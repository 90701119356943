import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import AppZipFileInput from '@tenant/core/AppZipFileInput';
import {
  APP_CONNECTIVITY_STATE_ENUM,
  APP_TYPE_ENUM,
} from '@tenant/utility/constants/enum';
import { getBlobFileImage } from 'pages/publishApp/helpers/common';
import { memo, useMemo } from 'react';
import FeatureStepMobileMarketPlace from './FeatureStepMobileMarketPlace';
import AppGridContainer from '@tenant/core/AppGridContainer';

const MobileStep = ({ values, errors, setFieldValue, setCurrentValues }) => {
  const showConnectivityState = useMemo(() => {
    return !(values.IntegrationMode === APP_TYPE_ENUM['Module']);
  }, [values.IntegrationMode]);

  const showBundleZipFile = useMemo(() => {
    const isOfflineState =
      values.AppConnectivityState === APP_CONNECTIVITY_STATE_ENUM['Offline'];
    return showConnectivityState && isOfflineState;
  }, [values.AppConnectivityState]);

  const showMobileAppUrl = useMemo(() => {
    const isOnlineState =
      values.AppConnectivityState === APP_CONNECTIVITY_STATE_ENUM['Online'];
    return showConnectivityState && isOnlineState;
  }, [values.AppConnectivityState]);

  return (
    <Grid item xs={12} sm={12} md={12}>
      <Box component='p' color='text.primary' fontSize={14} mb={2}>
        Mobile Fields
      </Box>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        container
        sx={{
          borderWidth: 1,
          borderRadius: 2,
          borderColor: '#f5f5f5',
          borderStyle: 'solid',
          padding: 3,
          boxSizing: 'border-box',
        }}
      >
        <Grid container item xs={12} direction='row' spacing={2} mb={4}>
          {/* App Connectivity State */}
          {showConnectivityState && (
            <Grid item xs={12} sm={6}>
              <Box component='p' color='text.primary' fontSize={14} mb={2}>
                App Connectivity State
                <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                  *
                </Box>
              </Box>
              <FormControl
                fullWidth
                error={Boolean(errors.AppConnectivityState)}
              >
                <Select
                  value={values.AppConnectivityState}
                  onChange={(e) => {
                    setFieldValue('AppConnectivityState', e.target.value);
                    setCurrentValues({
                      ...values,
                      AppConnectivityState: e.target.value,
                    });
                  }}
                >
                  {Object.entries(APP_CONNECTIVITY_STATE_ENUM)
                    .map(([value, key]) => ({
                      key: key,
                      value: value,
                    }))
                    .map((item, index) => (
                      <MenuItem key={index} value={item.key}>
                        {item.value}
                      </MenuItem>
                    ))}
                </Select>
                {Boolean(errors.AppConnectivityState) && (
                  <FormHelperText>{errors.AppConnectivityState}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          )}

          {/* App Code */}
          <Grid item xs={12} sm={showConnectivityState ? 6 : 12}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Mobile Feature Code
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <TextField
              disabled={values.AppId ? true : false}
              placeholder='Enter text'
              error={!!errors.MobileAppCode}
              helperText={errors.MobileAppCode}
              value={values.MobileAppCode ?? ''}
              onChange={async (e) => {
                setFieldValue('MobileAppCode', e.target.value);
              }}
              onBlur={(e) => {
                setFieldValue('MobileAppCode', values.MobileAppCode?.trim());
              }}
              fullWidth
            />
          </Grid>
        </Grid>

        {/* Bundle Zip File in case app Offline*/}
        {showBundleZipFile && (
          <Grid item xs={12}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Bundle zip file
            </Box>
            <AppZipFileInput
              defaultFile={values.BundleZipFile}
              error={!!errors.BundleZipBlobReference}
              helperText={errors.BundleZipBlobReference}
              appUrl={
                values.AppConnectivityState ===
                  APP_CONNECTIVITY_STATE_ENUM['Offline'] && values.MobileAppUrl
                  ? values.MobileAppUrl
                  : null
              }
              onChange={(file) => {
                if (file) {
                  getBlobFileImage(file, (blob) => {
                    setFieldValue('BundleZipBlobReference', blob);
                  });
                } else {
                  setFieldValue('BundleZipBlobReference', null);
                }
                setFieldValue('BundleZipFile', file);
              }}
            />
          </Grid>
        )}

        {/* Mobile app URL in case app Online, but we temporary saved it into MobileFeatureUrl first*/}
        {showMobileAppUrl && (
          <Grid item xs={12} sm={12}>
            <Box component='p' color='text.primary' fontSize={14} mb={2}>
              Mobile App URL
              <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                *
              </Box>
            </Box>
            <TextField
              placeholder='Enter URL'
              error={!!errors.MobileFeatureUrl}
              helperText={errors.MobileFeatureUrl}
              value={values.MobileFeatureUrl ?? ''}
              onChange={async (e) => {
                setFieldValue('MobileFeatureUrl', e.target.value);
              }}
              onBlur={(e) => {
                setFieldValue(
                  'MobileFeatureUrl',
                  values.MobileFeatureUrl?.trim(),
                );
              }}
              fullWidth
            />
          </Grid>
        )}
      </Grid>

      {values.IntegrationMode === APP_TYPE_ENUM['Embed'] && (
        <AppGridContainer>
          <FeatureStepMobileMarketPlace
            values={values}
            errors={errors}
            setFieldValue={setFieldValue}
          />
        </AppGridContainer>
      )}
    </Grid>
  );
};

export default memo(MobileStep);
