// src/ImageInput.js
import React, { useEffect, useState } from 'react';
import { Box, Button, Typography, Input, Grid } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const AppInputImage = ({
  defaultFile,
  onChange = () => {},
  error,
  helperText,
  appUrl,
}) => {
  const [image, setImage] = useState(defaultFile);
  const [imageName, setImageName] = useState(defaultFile?.name);

  useEffect(() => {
    if (defaultFile) {
      setImage(URL.createObjectURL(defaultFile));
      setImageName(defaultFile.name);
    }
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setImageName(file.name);
      onChange(file);
    }
  };

  return (
    <Grid container spacing={2} columns={16}>
      <Grid item xs={6} sm={4} md={2}>
        {image || appUrl ? (
          <Box
            component={'img'}
            src={image || appUrl}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              height: '125px',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: 2,
              width: '100%',
              height: '100%',
            }}
          />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
              height: '125px',
              border: '1px solid #ddd',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              borderRadius: 2,
              width: '100%',
              height: '100%',
            }}
          />
        )}
      </Grid>

      <Grid item xs={10} sm={12} md={14}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 5,
            border: error ? '2px dashed #d32f2f' : '2px dashed #1976d2',
            borderRadius: 2,
            backgroundColor: '#f5f5f5',
            width: '100%',
            margin: 'auto',
            height: '100%',
          }}
        >
          {!image && (
            <Typography variant='body1' color='textSecondary' mb={2}>
              No image selected (accept format: .jpeg, .png, .gif)
            </Typography>
          )}

          {imageName && (
            <Typography variant='body1' color='textSecondary' mb={2}>
              {imageName}
            </Typography>
          )}

          <label htmlFor='image-upload'>
            <Input
              id='image-upload'
              type='file'
              inputProps={{
                accept: '.jpeg, .png, .gif',
              }}
              onChange={handleFileChange}
              sx={{ display: 'none' }}
            />
            <Button
              variant='contained'
              component='span'
              startIcon={<CloudUploadIcon />}
              color='primary'
            >
              Upload Image
            </Button>
          </label>
        </Box>
      </Grid>

      {!!helperText && (
        <Grid
          color={error && 'error.main'}
          item
          xs={12}
          sm={12}
          md={12}
          sx={{ fontSize: '0.75rem' }}
        >
          {helperText}
        </Grid>
      )}
    </Grid>
  );
};

export default React.memo(AppInputImage);
