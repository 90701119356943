import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { validatePublishApp } from 'pages/listApp/helpers/validate';
import usePublishApp from 'pages/listApp/hooks/usePublishApp';
import RocketIcon from '@mui/icons-material/Rocket';
import { PLATFORM_ENUM } from '@tenant/utility/constants/enum';
import AppVersion from './AppVersion';
import AppVersionLog from './AppVersionLog';

const PublishApp = ({ data }) => {
  const { appId } = useParams();
  const [open, setOpen] = useState(false);
  const { mutate } = usePublishApp();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSubmit = async (values) => {
    mutate({ AppId: appId, ...values });
  };

  return (
    <>
      <Button
        variant='contained'
        startIcon={<RocketIcon />}
        onClick={handleOpen}
      >
        Publish App
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: 20 }}>
          Are you sure you want to publish this app?
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              AppVersion: data?.AppVersion,
              Platform: data?.Platform,
              LaunchedType: data?.LaunchedType,
            }}
            validationSchema={validatePublishApp}
            onSubmit={handleSubmit}
          >
            {({ values, errors, setFieldValue, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <Stack direction='column' spacing={4}>
                  {data.Platform === PLATFORM_ENUM['Web'] && (
                    <>
                      <AppVersion
                        title={'Web App Version'}
                        fieldKey={'AppVersion'}
                        errorText={errors.AppVersion}
                        valueText={values.AppVersion}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersionLog
                        title={'Web App Version Log'}
                        fieldKey={'AppVersionLog'}
                        errorText={errors.AppVersionLog}
                        valueText={values.AppVersionLog}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )}

                  {data.Platform === PLATFORM_ENUM['Mobile'] && (
                    <>
                      <AppVersion
                        title={'Mobile App Version'}
                        fieldKey={'MobileAppVersion'}
                        errorText={errors.MobileAppVersion}
                        valueText={values.MobileAppVersion}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersionLog
                        title={'Mobile App Version Log'}
                        fieldKey={'MobileAppVersionLog'}
                        errorText={errors.MobileAppVersionLog}
                        valueText={values.MobileAppVersionLog}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )}

                  {data.Platform === PLATFORM_ENUM['Cross_Platform'] && (
                    <>
                      <AppVersion
                        title={'Web App Version'}
                        fieldKey={'AppVersion'}
                        errorText={errors.AppVersion}
                        valueText={values.AppVersion}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersionLog
                        title={'Web App Version Log'}
                        fieldKey={'AppVersionLog'}
                        errorText={errors.AppVersionLog}
                        valueText={values.AppVersionLog}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersion
                        title={'Mobile App Version'}
                        fieldKey={'MobileAppVersion'}
                        errorText={errors.MobileAppVersion}
                        valueText={values.MobileAppVersion}
                        setFieldValue={setFieldValue}
                      />

                      <AppVersionLog
                        title={'Mobile App Version Log'}
                        fieldKey={'MobileAppVersionLog'}
                        errorText={errors.MobileAppVersionLog}
                        valueText={values.MobileAppVersionLog}
                        setFieldValue={setFieldValue}
                      />
                    </>
                  )}
                </Stack>
                <Stack
                  spacing={2}
                  direction='row'
                  sx={{ mt: 2 }}
                  justifyContent='flex-end'
                >
                  <Button
                    variant='text'
                    color='secondary'
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button variant='contained' color='primary' type='submit'>
                    Publish
                  </Button>
                </Stack>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PublishApp;
