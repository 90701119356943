import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getListClientsByAppInstance } from '../apis';

const useGetListClientsByAppInstance = (appInstanceId) => {
  const getListData = useQuery({
    queryKey: ['app-clients-list-by-app-instance-' + appInstanceId],
    queryFn: () => getListClientsByAppInstance(appInstanceId),
  });

  return getListData;
};

export default useGetListClientsByAppInstance;
