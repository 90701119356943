import React from 'react';
import { Navigate } from 'react-router-dom';
import { initialUrl } from 'constants/AppConst';
import { authRouteConfig } from './auth';
import Error403 from './errorPages/Error403';
import { errorPagesConfigs } from './errorPages';
import { accountPagesConfigs } from './account';
import { permissionRoutes } from './permissions';
import { listAppRoutes } from './listApp';
import { publishAppRoutes } from './publishApp';
import { dockerImageRoutes } from './dockerImage';
import { appPackageRoutes } from './appPackage';
import { appInstanceRoutes } from './appInstance';
import { containerAppRoutes } from './containerApp';

const authorizedStructure = {
  fallbackPath: '/signin',
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...accountPagesConfigs,
    ...permissionRoutes,
    ...listAppRoutes,
    ...publishAppRoutes,
    ...dockerImageRoutes,
    ...appPackageRoutes,
    ...appInstanceRoutes,
    ...containerAppRoutes,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs.concat([
    {
      path: '/',
      element: <Navigate to={initialUrl} />,
    },
    {
      path: '*',
      element: <Navigate to='/error-pages/error-404' />,
    },
  ]),
};
export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
