import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { deleteDockerImageQuery } from '../apis';
import { removeItem } from '..';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useDeleteDockerImage = () => {
  const dispatch = useDispatch();

  const mutateQuery = useMutation({
    mutationFn: deleteDockerImageQuery,
    onSuccess: (data) => {
      toast.success('Delete Docker Image successfully');
      dispatch(removeItem(data?.data));
    },
    onError: (error) => {
      const message = error?.response?.data?.title ?? 'Server error';
      toast.error(message);
      console.error('Error Delete docker image:', error);
    },
  });

  return mutateQuery;
};

export default useDeleteDockerImage;
