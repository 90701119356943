import { PLATFORM_ENUM } from '@tenant/utility/constants/enum';
import * as Yup from 'yup';

export const validatePublishApp = Yup.object().shape({
  AppVersion: Yup.string().when('Platform', {
    is: (val) => {
     return false;
    },
    then: Yup.string()
      .matches(/^\d+\.\d+\.\d+(-\S*)?(\+\S*)?$/, 'Invalid version format')
      .required('App version is required'),
    otherwise: Yup.string().nullable(),
  }),
  AppVersionLog: Yup.string().nullable(),
  MobileAppVersion: Yup.string().when('Platform', {
    is: (val) => {
      const isSupportMobile =
        val === PLATFORM_ENUM['Mobile'] ||
        val === PLATFORM_ENUM['Cross_Platform'];
      return isSupportMobile;
    },
    then: Yup.string()
      .matches(/^\d+\.\d+\.\d+(-\S*)?(\+\S*)?$/, 'Invalid version format')
      .required('Mobile App Version is required'),
    otherwise: Yup.string().nullable(),
  }),
});
