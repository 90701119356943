import { RoutePermittedRole } from 'constants/AppConst';
import { ROUTE_STRING } from 'constants/RouteString';
import List from './List';

export const dockerImageRoutes = [
  {
    permittedRole: RoutePermittedRole.User,
    path: [
      ROUTE_STRING.DOCKER_IMAGE.LIST,
      ROUTE_STRING.DOCKER_IMAGE.ADD,
      ROUTE_STRING.DOCKER_IMAGE.VIEW,
    ],
    element: <List />,
  },
];
