import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import useStartAppContainer from 'redux/slices/containerApp/hooks/useStartAppContainer';
import useStopAppContainer from 'redux/slices/containerApp/hooks/useStopAppContainer';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import checkCanStopContainerApp from 'redux/slices/appInstance/helpers/checkCanStopContainerApp';

const StartAndStopProgress = ({
  containerAppId,
  appInstanceId,
  status,
  callBack,
}) => {
  const canStop = useMemo(() => checkCanStopContainerApp(status), [status]);
  const startMutate = useStartAppContainer();
  const stopMutate = useStopAppContainer();

  const onStart = () => {
    startMutate.mutate(
      { containerAppId, appInstanceId },
      {
        onSuccess() {
          callBack?.();
        },
      },
    );
  };

  const onStop = () => {
    stopMutate.mutate(
      { containerAppId, appInstanceId },
      {
        onSuccess() {
          callBack?.();
        },
      },
    );
  };

  return (
    <>
      {!canStop ? (
        <LoadingButton
          loading={startMutate.isPending}
          startIcon={<PlayArrowIcon />}
          onClick={onStart}
        >
          Start
        </LoadingButton>
      ) : (
        <LoadingButton
          color='warning'
          loading={stopMutate.isPending}
          startIcon={<StopIcon />}
          onClick={onStop}
        >
          Stop
        </LoadingButton>
      )}
    </>
  );
};

export default React.memo(StartAndStopProgress);

StartAndStopProgress.propTypes = {
  containerAppId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  appInstanceId: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};
