import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RoutePermittedRole } from 'constants/AppConst';
import { Box, Breadcrumbs, Stack, Tab, Tabs, Typography } from '@mui/material';
import AppsContainer from '@tenant/core/AppsContainer';
import RolePermission from './components/RolePermission';
import UserRole from './components/UserRole';
import Role from './components/Role';
import usePermissionService from './hooks/usePermissionService';
import AppLoader from '@tenant/core/AppLoader';
import AllowedAccess from '@tenant/core/AppPermission/AllowedAccess';

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const Permission = () => {
  const [value, setValue] = useState(0);
  const [roleList, setRoleList] = useState([]);
  const { loading, getListRole } = usePermissionService();

  const handleGetList = useCallback(() => {
    getListRole({
      onSuccess: (data) => {
        setRoleList(data.Items ? structuredClone(data.Items) : []);
      },
    });
  }, [getListRole]);

  useEffect(() => {
    handleGetList();
  }, [handleGetList]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Stack direction='row' justifyContent='space-between'>
        <Breadcrumbs sx={{ py: 1 }} aria-label='breadcrumb'>
          <Typography color='text.primary'>Admin</Typography>
          <Typography color='text.primary'>Authorization</Typography>
        </Breadcrumbs>
      </Stack>
      <AppsContainer fullView>
        {loading && <AppLoader />}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab label='Roles' {...a11yProps(0)} />
            <Tab label='Assignments' {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Role roleList={roleList} callbackSubmit={handleGetList} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <UserRole canEdit canRemove />
        </CustomTabPanel>
      </AppsContainer>
    </>
  );
};

export default React.memo(Permission);

Permission.propTypes = {};

const PermissionInRole = React.lazy(() => import('./PermissionInRole'));

export const permissionRoutes = [
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/permissions',
    element: (
      <AllowedAccess isPermissionManager isPage404>
        <Permission />
      </AllowedAccess>
    ),
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: '/admin/permissions/:roleId',
    element: (
      <AllowedAccess isPermissionManager isPage404>
        <PermissionInRole />
      </AllowedAccess>
    ),
  },
];
