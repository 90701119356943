import PreviewDetailImg from 'assets/images/feature-slots/preview_detail.png'
import PreviewDListlImg from 'assets/images/feature-slots/preview_list.png'

export const listPages = [
  { matchRegex: '/Job$', name: 'Job listing', slots: ['slotAdditionMenu'], image: PreviewDListlImg },
  {
    matchRegex: '/Job\\/Detail/',
    name: 'Job detail',
    slots: ['slotAdditionMenu', 'slotTab', 'slotContent'],
    image: PreviewDetailImg
  },

  { matchRegex: '/Site$', name: 'Site listing', slots: ['slotAdditionMenu'], image: PreviewDListlImg },
  {
    matchRegex: '/Site\\/Detail/',
    name: 'Site detail',
    slots: ['slotAdditionMenu', 'slotTab', 'slotContent'],
    image: PreviewDetailImg
  },

  {
    matchRegex: '/Customer$',
    name: 'Customer listing',
    slots: [
      'slotAdditionMenu',
      // 'slotTableColumnAttribute',
      //  'slotTableColumnAttributeTest'
    ],
    image: PreviewDListlImg
  },
  {
    matchRegex: '/Customer\\/Detail/',
    name: 'Customer detail',
    slots: ['slotAdditionMenu', 'slotTab', 'slotContent'],
    image: PreviewDetailImg
  },

  {
    matchRegex: '/Quote$',
    name: 'Quote listing',
    slots: ['slotAdditionMenu'],
    image: PreviewDListlImg
  },
  {
    matchRegex: '/Quote\\/Detail/',
    name: 'Quote detail',
    slots: ['slotAdditionMenu', 'slotTab', 'slotContent'],
    image: PreviewDetailImg
  },

  {
    matchRegex: '/Asset$',
    name: 'Asset listing',
    slots: ['slotAdditionMenu'],
    image: PreviewDListlImg
  },
  {
    matchRegex: '/Asset\\/Detail/',
    name: 'Asset detail',
    slots: ['slotAdditionMenu', 'slotTab', 'slotContent'],
    image: PreviewDetailImg
  },

  {
    matchRegex: '/Invoice$',
    name: 'Invoice listing',
    slots: ['slotAdditionMenu'],
    image: PreviewDListlImg
  },
  {
    matchRegex: '/Invoice\\/Detail/',
    name: 'Invoice detail',
    slots: ['slotAdditionMenu', 'slotTab', 'slotContent'],
    image: PreviewDetailImg
  },

  // Phase 2
  // {
  //   matchRegex: '/Library$',
  //   name: 'Setting Library',
  //   slots: ['slotListGrid'],
  // },

];

export const listDirection = [
  { key: 'append', label: 'End' },
  { key: 'prepend', label: 'Start' },
];