import { useQuery } from '@tanstack/react-query';
import { getAppCategories } from 'api/appManager';
import { LIST_APP_CATEGORIES_QUERY } from 'constants/QueryString';

const useAppCategoriesQuery = () => {
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: [LIST_APP_CATEGORIES_QUERY],
    queryFn: async () => {
      try {
        return await getAppCategories();
      } catch (error) {
        throw error;
      }
    },
  });

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useAppCategoriesQuery;