import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { stopContainerApp } from '../apis';
import { addItem } from '..';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useStopAppContainer = () => {
  const dispatch = useDispatch();

  const mutationData = useMutation({
    mutationFn: stopContainerApp,
    onSuccess: (data) => {
      toast.success('Run stop app container successfully');
    },
    onError: (error) => {
      const message = error?.response?.data?.title ?? 'Server error';
      toast.error(message);
      console.error('Error run stop app container:', error);
    },
  });

  return mutationData;
};

export default useStopAppContainer;
