import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getDetailAppPackageQuery } from '../apis';
import { setFieldAppPackage } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';

const useGetDetailAppPackage = (id) => {
  const dispatch = useDispatch();

  const getData = useQuery({
    queryKey: ['app-package-detail-' + id],
    queryFn: () => getDetailAppPackageQuery(id),
  });

  return getData;
};

export default useGetDetailAppPackage;
