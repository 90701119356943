import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { getListAppPackageQuery } from '../apis';
import { setFieldAppPackage } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';

const useGetListAppPackage = () => {
  const dispatch = useDispatch();

  const getListAppPackage = useQuery({
    queryKey: ['app-package-list'],
    queryFn: getListAppPackageQuery,
  });

  useEffect(() => {
    const data =
      typeof getListAppPackage !== 'undefined' ? getListAppPackage?.data : [];
    if (data) {
      dispatch(
        setFieldAppPackage({ field: 'list', value: deepClone(data?.data) }),
      );
    }
  }, [getListAppPackage?.data, dispatch]);

  return getListAppPackage;
};

export default useGetListAppPackage;
