import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Error404 from 'pages/errorPages/Error404';
import { useSelector } from 'react-redux';

const AllowedAccess = ({
  isPermissionManager,
  permissionIds = [],
  children,
  isPage404,
}) => {
  const { user } = useSelector((s) => s.authorization);
  const { Permissions, IsPermissionManager } = user || {};
  const canAccess = useMemo(
    () =>
      isPermissionManager
        ? IsPermissionManager
        : Permissions?.filter((permission) =>
            permissionIds.some(
              (permissionId) => permissionId === permission.PermissionId,
            ),
          ),
    [Permissions, permissionIds],
  );
  return canAccess ? <>{children}</> : <>{isPage404 ? <Error404 /> : ''}</>;
};

export default React.memo(AllowedAccess);

AllowedAccess.propTypes = {
  permissionIds: PropTypes.array,
  children: PropTypes.element,
  isPage404: PropTypes.bool,
  isPermissionManager: PropTypes.bool,
};
