import React, { useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { FieldArray } from 'formik';

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import DeleteSharpIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PageSelector from './elements/PageSelector';
import Typography from '@mui/material/Typography';
import { ReactComponent as NoItem } from '../../../../assets/icon/no-item.svg';
import IconButton from '@mui/material/IconButton';
import AlertConfirmDialog from '../../../../components/AlertConfirmDialog';
import useGetBAUModulesQuery from 'pages/publishApp/hooks/useGetBAUModules';

const FeatureStepMobileMarketPlace = ({ values }) => {
  const { data: bauModules = [] } = useGetBAUModulesQuery();

  const [currentListPagesAvailable, setCurrentListPagesAvailable] =
    React.useState([]);

  const filteredBauModules = useMemo(() => {
    const selectedPages = values.MobileBAUModuleIDs ?? [];
    if (bauModules.length > 0) {
      return bauModules.filter((item) => !selectedPages.includes(item.id));
    }
    return [];
  }, [bauModules, values.MobileBAUModuleIDs]);

  React.useEffect(() => {
    if (bauModules) {
      setCurrentListPagesAvailable(cloneDeep(bauModules));
      setCurrentListPagesAvailable(filteredBauModules);
    }
  }, [bauModules, values.MobileBAUModuleIDs]);

  const getPageInfo = (pageKey, keyField) => {
    const result = bauModules.find((item) => item.id === pageKey);

    if (result && keyField) return get(result, keyField);
    return result;
  };

  const pageItemObj = { Id: '' };

  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [currentAction, setCurrentAction] = React.useState(null);

  const handleClickOpenConfirm = (action, ...args) => {
    setOpenConfirmDelete(true);
    setCurrentAction({ action, args });
  };

  const handleClose = () => {
    setOpenConfirmDelete(false);
    setCurrentAction(null);
  };

  const handleConfirm = () => {
    if (currentAction) {
      setCurrentAction(null);
      const { action, args } = currentAction;
      if (typeof action === 'function') action(...args);
    }
    setOpenConfirmDelete(false);
  };

  return (
    <>
      <AlertConfirmDialog
        open={openConfirmDelete}
        title='Remove Module'
        message='Are you sure you want to remove this module?'
        onConfirm={handleConfirm}
        onCancel={handleClose}
      />
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            height: '1px',
            width: 'full',
            backgroundColor: '#8080804a',
            display: 'block',
          }}
        ></Box>
      </Grid>

      <FeatureConfigurationHeader
        values={values}
        currentListPagesAvailable={currentListPagesAvailable}
        pageItemObj={pageItemObj}
      />

      <Grid item xs={12} sm={12} md={12} sx={{ mt: 4 }}>
        <FieldArray name='MobileBAUModuleIDs'>
          {({ remove: removePage }) => (
            <>
              {(values.MobileBAUModuleIDs.length || 0) > 0 ? (
                values.MobileBAUModuleIDs.map((itempage, index) => (
                  <Box
                    key={index}
                    mb={4}
                    sx={{
                      '&:hover': {
                        boxShadow: 1,
                      },
                      cursor: 'pointer',
                      border: 1,
                      borderColor: '#8080804a',
                      borderRadius: 2,
                      p: 4,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 4,
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                      <Typography>
                        {getPageInfo(
                          get(values, `MobileBAUModuleIDs.${index}`),
                          'name',
                        )}
                      </Typography>
                    </Box>

                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClickOpenConfirm(removePage, index);
                      }}
                    >
                      <DeleteSharpIcon size='small' sx={{ color: 'grey' }} />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '200px',
                  }}
                >
                  <NoItem />

                  <Typography sx={{ marginTop: 2 }} variant='caption'>
                    No item displayed
                  </Typography>

                  <Box sx={{ marginTop: 2 }}>
                    <FieldArray name='MobileBAUModuleIDs'>
                      {({ push: pushPage }) => (
                        <PageSelector
                          options={currentListPagesAvailable}
                          onChange={(value) => {
                            pushPage(value.id);
                          }}
                        />
                      )}
                    </FieldArray>
                  </Box>
                </Box>
              )}
            </>
          )}
        </FieldArray>
      </Grid>
    </>
  );
};

function FeatureConfigurationHeader({ values, currentListPagesAvailable }) {
  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant='h4' gutterBottom>
              Feature Configuration for Mobile Market Place
            </Typography>
            <Typography variant='caption' gutterBottom>
              Customize and manage additional features for this specific screen.
            </Typography>
          </Box>

          <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            {(values.MobileBAUModuleIDs?.length || 0) > 0 && (
              <FieldArray name='MobileBAUModuleIDs'>
                {({ push: pushPage }) => (
                  <PageSelector
                    options={currentListPagesAvailable}
                    onChange={(value) => {
                      pushPage(value.id);
                    }}
                  />
                )}
              </FieldArray>
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default React.memo(FeatureStepMobileMarketPlace);
