import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createApp } from 'api/appManager';
import { LIST_APP_QUERY } from 'constants/QueryString';
import { toast } from 'react-toastify';

const useCreateApp = () => {
  const queryClient = useQueryClient();

  const {
    data,
    error,
    isError,
    isIdle,
    isPending,
    isPaused,
    isSuccess,
    failureCount,
    failureReason,
    mutate,
    mutateAsync,
    reset,
    status,
    submittedAt,
    variables,
  } = useMutation({
    mutationFn: createApp,
    onSuccess: (data) => {
      queryClient.invalidateQueries([LIST_APP_QUERY]);
    },
    onError: (error) => {
      const message = error?.message ?? 'Server Error';
      toast.error(message);
      console.error('Error creating user:', error);
      // Handle the error, show error message, etc.
    },
  });
  return {
    data,
    error,
    isError,
    isIdle,
    isPending,
    isPaused,
    isSuccess,
    failureCount,
    failureReason,
    mutate,
    mutateAsync,
    reset,
    status,
    submittedAt,
    variables,
  };
};

export default useCreateApp;
