import React from 'react';
import {
  Avatar,
  Typography,
  Rating,
  Stack,
  FormGroup,
  FormControlLabel,
  Switch,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from '@mui/material';
import useToggleShowReviews from 'pages/listApp/hooks/useToggleShowReviews';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'constants/Common';

const Comment = ({
  id,
  userAvatar,
  userName,
  commentText,
  rating,
  reviewDate,
  isHidden,
}) => {
  const { mutate } = useToggleShowReviews();

  const handleToggleShowReviewsOnMarketplace = () => {
    mutate({id});
  };

  return (
    <ListItem sx={{ alignItems: 'flex-start', p: 0 }}>
      <ListItemAvatar sx={{ mt: 4 }}>
        <Avatar src={userAvatar} alt={userName} />
      </ListItemAvatar>
      <ListItemText
        disableTypography
        primary={
          <>
            <Stack
              direction='row'
              alignItems='center'
              justifyContent='space-between'
            >
              <Typography variant='h4'>{userName}</Typography>
              <FormGroup>
                <FormControlLabel
                  control={<Switch defaultChecked={!isHidden} />}
                  label='Show on Marketplace'
                  onChange={handleToggleShowReviewsOnMarketplace}
                />
              </FormGroup>
            </Stack>
          </>
        }
        secondary={
          <>
            <Stack direction='row' spacing={4} alignItems='center'>
              <Rating value={rating} readOnly size='small' />
              <Typography variant='caption'>
                {reviewDate && moment(reviewDate).format(DATE_TIME_FORMAT)}
              </Typography>
            </Stack>
            <Typography
              variant='body2'
              color='textSecondary'
              component='p'
              style={{ marginTop: '8px' }}
            >
              {commentText}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
};

export default Comment;
