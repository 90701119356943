import React from 'react';
import {
  Button,
  Typography,
  TextField,
  Grid,
  Box,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactComponent as NoItem } from '../../../../assets/icon/no-item.svg';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';

const CollapsibleCard = ({
  label,
  environments,
  setFieldValue,
  deleteEnv,
  addNewRow,
  readonly,
}) => {
  const checkRequiredValid = (value) => {
    return !!value;
  };

  return (
    <>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography variant='h5'> {label}</Typography>

        {!readonly && (
          <Button sx={{ mb: 2, mt: 2 }} onClick={addNewRow}>
            Add New Row{' '}
          </Button>
        )}
      </Box>
      {environments?.map((env, idx) => (
        <Grid
          key={env.id}
          mt={4}
          mb={4}
          alignItems='start'
          container
          spacing={2}
        >
          <Grid item xs={4}>
            <TextField
              disabled={readonly || env.isRequired}
              required
              fullWidth
              value={env.key}
              label='Key'
              sx={{ '& .Mui-disabled': { background: grey[50] } }}
              error={!checkRequiredValid(env.key)}
              helperText={!checkRequiredValid(env.key) && 'Key is required'}
              onChange={(e) => {
                setFieldValue(idx, 'key', e.target.value);
                setFieldValue(
                  idx,
                  'isValid',
                  checkRequiredValid(env.value) &&
                    checkRequiredValid(e.target.value),
                );
              }}
            />
          </Grid>
          <Grid item xs={readonly ? 8 : 7}>
            <TextField
              disabled={readonly}
              fullWidth
              required
              value={env.value}
              label='Value'
              error={!checkRequiredValid(env.value)}
              helperText={!checkRequiredValid(env.value) && 'Value is required'}
              onChange={(e) => {
                setFieldValue(idx, 'value', e.target.value);
                setFieldValue(
                  idx,
                  'isValid',
                  checkRequiredValid(e.target.value) &&
                    checkRequiredValid(env.key),
                );
              }}
            />
          </Grid>
          {!readonly && !env.isRequired && (
            <Grid item xs={1} sx={{ textAlign: 'right' }}>
              <IconButton aria-label='delete' onClick={() => deleteEnv(env)}>
                <DeleteIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ))}

      {!environments?.length && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        >
          <NoItem />

          <Typography sx={{ marginTop: 2 }} variant='caption'>
            No item displayed
          </Typography>
        </Box>
      )}
    </>
  );
};

export default React.memo(CollapsibleCard);

CollapsibleCard.propTypes = {
  label: PropTypes.string,
  environments: PropTypes.array,
  setFieldValue: PropTypes.func,
  deleteEnv: PropTypes.func,
  addNewRow: PropTypes.func,
  readonly: PropTypes.bool,
};
