import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import useGetListClientsByAppInstance from 'redux/slices/appInstance/hooks/useGetListClientsByAppInstance';
import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import useModal from '@tenant/utility/hooks/useModal';
import IdpClientModalForm from './IdpClientModalForm';
import IdpClientEnum from 'constants/IdpClientEnum';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '@tenant/core/App/Dialog/DeleteDialog';
import useDeleteClientByAppInstance from 'redux/slices/appInstance/hooks/useDeleteClientByAppInstance';
import useUpdateClientByAppInstance from 'redux/slices/appInstance/hooks/useUpdateClientByAppInstance';
import { useTheme } from '@emotion/react';
import { GridActionsCellItem } from '@mui/x-data-grid';

const IdpClientTable = ({ appInstanceId, newClients = [] }) => {
  const getListQuery = useGetListClientsByAppInstance(appInstanceId);
  const deleteClientMutate = useDeleteClientByAppInstance();
  const updateClientMutate = useUpdateClientByAppInstance();
  const [selected, setSelected] = useState();
  const theme = useTheme();
  const isFlex = useMediaQuery(theme.breakpoints.up('xl'));
  const { visible, onShow, onClose } = useModal();
  const {
    visible: visibleDelete,
    onShow: onShowDelete,
    onClose: onCloseDelete,
  } = useModal();

  const handleShow = (item) => {
    setSelected(item);
    onShow();
  };

  const handleClose = () => {
    setSelected();
    onClose();
  };

  const handleShowDelete = (item) => {
    setSelected(item);
    onShowDelete();
  };

  const handleCloseDelete = () => {
    setSelected();
    onCloseDelete();
  };

  const handleUpdate = (data) => {
    updateClientMutate.mutate(
      {
        ...data,
        appInstanceId,
        idpClientId: selected.Id,
      },
      {
        onSuccess() {
          handleClose();
          getListQuery.refetch();
        },
      },
    );
  };

  const handleSaveDelete = () => {
    deleteClientMutate.mutate(
      {
        appInstanceId,
        idpClientId: selected.Id,
      },
      {
        onSuccess() {
          handleCloseDelete();
          getListQuery.refetch();
        },
      },
    );
  };

  const columns = [
    {
      field: 'ClientName',
      headerName: 'Client ID',
      width: 300,
      sortable: true,
      flex: isFlex ? 1 : undefined,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'AuthenticationFlow',
      headerName: 'Flow',
      width: 175,
      sortable: true,
      renderCell: (params) =>
        IdpClientEnum.find((e) => e.Value === params.value)?.Description,
    },
    {
      field: 'RedirectUris',
      headerName: 'Redirect Uri',
      width: 300,
      sortable: true,
      renderCell: (params) => (
        <Box sx={{ whiteSpace: 'normal', pt: 5, pb: 5 }}>
          {params.value ? params.value.join('\n') : '-'}
        </Box>
      ),
    },
    {
      field: 'LogoutRedirectUris',
      headerName: 'Logout Redirect Uri',
      width: 300,
      sortable: true,
      renderCell: (params) => (
        <Box sx={{ whiteSpace: 'normal', pt: 5, pb: 5 }}>
          {params.value ? params.value.join('\n') : '-'}
        </Box>
      ),
    },
    {
      field: 'Action',
      headerName: '',
      width: 50,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ minWidth: 150 }}
          key='app-request-action--approve'
          label='Edit'
          showInMenu
          onClick={() => handleShow(params.row)}
        />,
        <GridActionsCellItem
          key='app-request-action--approve'
          label='Delete'
          showInMenu
          onClick={() => handleShowDelete(params.row)}
        />,
      ],
    },
  ];

  const rows = getListQuery?.data?.data
    ? [...newClients, ...(getListQuery?.data?.data ?? [])]
    : [...newClients];

  return (
    <>
      <IdpClientModalForm
        isEdit
        visible={visible}
        loading={updateClientMutate.isPending}
        handleClose={handleClose}
        handleSubmit={handleUpdate}
        initialValues={
          selected
            ? {
                ...selected,
                RedirectUri: selected.RedirectUris.join('\n'),
                LogoutRedirectUri: selected.LogoutRedirectUris.join('\n'),
                AuthenticationFlow: IdpClientEnum.find(
                  (e) => e.Value === selected.AuthenticationFlow,
                ),
                IdpClientId: selected.Id,
              }
            : undefined
        }
      />

      <DeleteDialog
        visible={visibleDelete}
        loading={deleteClientMutate.isPending}
        handleClose={handleCloseDelete}
        handleSave={handleSaveDelete}
      />

      <DataGrid
        hideFooter
        disableColumnSelector
        disableColumnMenu
        loading={getListQuery.isPending || getListQuery.isRefetching}
        rows={rows}
        columns={columns}
        getRowId={(row) => row.ClientId}
        getRowHeight={() => 'auto'}
      />
    </>
  );
};

export default React.memo(IdpClientTable);

IdpClientTable.propTypes = {
  appInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  newClients: PropTypes.array,
};
