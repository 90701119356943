import { Box, Stack, TextField } from '@mui/material';
import { memo } from 'react';

const AppVersionLog = ({
  title,
  fieldKey,
  errorText,
  valueText,
  setFieldValue,
}) => {
  return (
    <Stack direction='column' spacing={2}>
      <Box component='p' color='text.primary' fontSize={14}>
        {title}
      </Box>
      <TextField
        multiline
        rows={4}
        placeholder='Enter text'
        name={fieldKey}
        error={!!errorText}
        helperText={errorText}
        value={valueText ?? ''}
        onChange={async (e) => {
          setFieldValue(fieldKey, e.target.value);
        }}
        onBlur={(e) => {
          setFieldValue(fieldKey, valueText?.trim());
        }}
        fullWidth
        minRows={4}
      />
    </Stack>
  );
};

export default memo(AppVersionLog);
