import React from 'react';
import useGetListExecutionContainerApp from 'redux/slices/containerApp/hooks/useGetListExecutionContainerApp';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import { inputFormatDateTime } from '@tenant/utility/constants/default';
import { grey } from '@mui/material/colors';

const Executions = () => {
  const { appInstanceId, id: containerAppId } = useParams();
  const { jobExecutions } = useSelector((s) => s.containerApp);
  const { isLoading, isRefetching } = useGetListExecutionContainerApp({
    appInstanceId,
    containerAppId,
  });

  const columns = [
    {
      field: 'ExecutionName',
      headerName: 'Execution Name',
      width: 300,
      flex: 1,
      sortable: true,
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      sortable: true,
      renderCell: (params) => (
        <Box
          sx={{
            color:
              params.value === 'Running'
                ? 'info.main'
                : params.value === 'Failed'
                ? 'error.main'
                : params.value === 'Succeeded'
                ? 'success.main'
                : grey[500],
          }}
        >
          {params.value}
        </Box>
      ),
    },
    {
      field: 'StartOn',
      headerName: 'Start On',
      width: 150,
      sortable: true,
      renderCell: (params) =>
        params.value
          ? formatDateFromISO(params.value, inputFormatDateTime)
          : '',
    },
    {
      field: 'EndOn',
      headerName: 'End On',
      width: 150,
      sortable: true,
      renderCell: (params) =>
        params.value
          ? formatDateFromISO(params.value, inputFormatDateTime)
          : '-',
    },
    {
      field: 'Duration',
      headerName: 'Duration',
      width: 100,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
      renderCell: (params) => params.value ?? '-',
    },
  ];

  return (
    <Box sx={{ height: 'calc(100vh - 225px)' }}>
      <DataGrid
        loading={isLoading || isRefetching}
        disableColumnSelector
        disableColumnMenu
        rows={jobExecutions ?? []}
        columns={columns}
        getRowId={(row) => row.ExecutionName}
      />
    </Box>
  );
};

export default React.memo(Executions);

Executions.propTypes = {};
