import React from 'react';
import clsx from 'clsx';
import AppContentView from '@tenant/core/AppContentView';
import AppFixedFooter from './AppFixedFooter';
import AppHeader from './AppHeader';
import { useLayoutContext } from '../../../utility/AppContextProvider/LayoutContextProvider';
import DefaultLayoutWrapper from './DefaultLayoutWrapper';
import MainContent from './MainContent';
import { LayoutType } from 'constants/AppEnums';
import AppSidebar from './AppSidebar';
import DefaultLayoutContainer from './DefaultLayoutContainer';
import { useSelector } from 'react-redux';
import AppLoader from '@tenant/core/AppLoader';

const DefaultLayout = () => {
  const { footer, layoutType, headerType, footerType } = useLayoutContext();
  const { loading: loadingAuth } = useSelector((s) => s.authorization);

  return (
    <DefaultLayoutContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <DefaultLayoutWrapper
        className={clsx('defaultLayoutWrapper', {
          appMainFooter: footer && footerType === 'fluid',
          appMainFixedFooter: footer && footerType === 'fixed',
          appMainFixedHeader: headerType === 'fixed',
        })}
      >
        <AppSidebar />

        {loadingAuth ? (
          <MainContent>
            <AppHeader />
            <AppContentView />
            <AppFixedFooter />
          </MainContent>
        ) : (
          <AppLoader />
        )}
      </DefaultLayoutWrapper>
    </DefaultLayoutContainer>
  );
};

export default DefaultLayout;
