import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { upgradeVersionAppPackageQuery } from '../apis';
import { addItem } from '..';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useUpgradeVersionAppPackage = () => {
  const dispatch = useDispatch();

  const mutateQuery = useMutation({
    mutationFn: upgradeVersionAppPackageQuery,
    onSuccess: (data) => {
      toast.success('Upgrade Version App Package successfully');
      dispatch(addItem(data?.data));
    },
    onError: (error) => {
      const message = error?.response?.data?.title ?? 'Server error';
      toast.error(message);
      console.error('Error Upgrade Version app package:', error);
    },
  });

  return mutateQuery;
};

export default useUpgradeVersionAppPackage;
