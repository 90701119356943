import { createSlice } from '@reduxjs/toolkit';
import reducers from './reducers';
import extraReducers from './extraReducers';

const initialState = {
  categoryList: [],
  loading: false,
  status: 'idle',
  isLoaded: false,
};

export default createSlice({
  name: 'selects',
  initialState: initialState,
  reducers: reducers,
  extraReducers: extraReducers,
});
