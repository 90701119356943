import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toggleCommentVisibility } from 'api/ratingManager';
import { LIST_APP_QUERY } from 'constants/QueryString';

const useToggleShowReviews = () => {
  const queryClient = useQueryClient()

  const {
    data,
    mutate,
  } = useMutation({
    mutationFn: toggleCommentVisibility,
    onSuccess: (data) => {
      queryClient.invalidateQueries([LIST_APP_QUERY]);
    },
    onError: (error) => {
      console.error('Error creating user:', error);
      // Handle the error, show error message, etc.
    },
  });
  return {
    data,
    mutate,
  };
};

export default useToggleShowReviews;
