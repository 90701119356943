import React from 'react';
import { Box } from '@mui/material';
import AppCKEditor from '@tenant/core/AppCKEditor';

const KeyBenefitStep = ({ values, errors, setFieldValue }) => {
  return (
    <Box sx={{ '& .ck-editor__editable': { minHeight: 350 } }}>
      <AppCKEditor
        initialData={values?.KeyBenefits}
        data={values?.KeyBenefits}
        error={!!errors.KeyBenefits}
        helperText={errors.KeyBenefits}
        handleChange={(event, editor) => {
          const data = editor.getData();
          setFieldValue('KeyBenefits', data);
        }}
      />
    </Box>
  );
};

export default React.memo(KeyBenefitStep);

KeyBenefitStep.propTypes = {};
