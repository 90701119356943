import React from 'react';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import Popover from '@mui/material/Popover';
import { styled, lighten, darken } from '@mui/system';

const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor: lighten(theme.palette.primary.light, 0.85),
  ...theme.applyStyles('dark', {
    backgroundColor: darken(theme.palette.primary.main, 0.8),
  }),
}));
const GroupItems = styled('ul')({
  padding: 0,
});

const PageSelector = ({ options, onChange }) => {
  // Popper
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  // Options
  const currentOptions = options.map((option) => {
    const letters = option.name.split(' ');
    return {
      firstLetter: letters[0],
      ...option,
    };
  });

  // Controlled state
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');

  return (
    <>
      <Button
        startIcon={<Add />}
        variant='contained'
        size='small'
        onClick={handleClick}
      >
        Add Page Customization
      </Button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ bgcolor: 'background.paper', borderRadius: 16, p: 2 }}>
          <Autocomplete
            value={value}
            onChange={(event, newValue) => {
              handleClose();
              onChange?.(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            options={currentOptions.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter),
            )}
            groupBy={(option) => option.firstLetter}
            getOptionLabel={(option) => option.name}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} size='small' placeholder='Search pages' />
            )}
            renderGroup={(params) => (
              <li key={params.key}>
                <GroupHeader>{params.group}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
              </li>
            )}
          />
        </Box>
      </Popover>
    </>
  );
};

export default React.memo(PageSelector);

PageSelector.propTypes = {};
