import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import jwtAxios from '@tenant/services/auth/jwt-auth';
import IntlMessages from '@tenant/utility/IntlMessages';

// Initial state
const initialState = {
  user: {},
  loading: true,
  error: '',
};

// Async thunk for fetching user info
export const getUserInfo = createAsyncThunk(
  'authorization/getUserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await jwtAxios.get('/api/User/GetUserInfo');
      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(
          <IntlMessages id='message.somethingWentWrong' />,
        );
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Create slice
const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    // No additional reducers for this example
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.user = action.payload;
        state.loading = false;
        state.error = '';
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export actions (if any)
export const {} = authorizationSlice.actions;

// Export reducer
export default authorizationSlice.reducer;
