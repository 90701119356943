import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CollapseCard from './CollapseCard';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import useModal from '@tenant/utility/hooks/useModal';
import CronModal from '@tenant/core/App/Cron/CronModal';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

const generateRandomID = () => {
  return 'id-' + Date.now() + '-' + Math.floor(Math.random() * 10000);
};

const AddEnvironmentDrawer = ({
  visible,
  handleClose,
  appType,
  title,
  requiredEnviromentVariables,
  cron,
  readonly,
  setContainerApps = () => {},
}) => {
  const isCron = useMemo(() => appType === 3, [appType]);
  const [environments, setEnvironments] = useState([]);
  const [cronValue, setCronValue] = useState(cron);
  const {
    visible: showSetCron,
    onShow: onShowSetCron,
    onClose: onCloseSetCron,
  } = useModal();

  useEffect(() => {
    setEnvironments(deepClone(requiredEnviromentVariables));
  }, [requiredEnviromentVariables]);

  const setFieldValue = (index, field, value) => {
    setEnvironments((prevEnvironments) =>
      prevEnvironments.map((env, i) =>
        i === index ? { ...env, [field]: value } : env,
      ),
    );
  };

  const deleteEnv = (env) => {
    setEnvironments((s) => s.filter((e) => e.id !== env.id));
  };

  const addNewRow = () => {
    setEnvironments((s) => [
      ...s,
      {
        id: 'new-item-' + generateRandomID(),
        key: '',
        value: '',
        isValid: false,
        isRequired: false,
      },
    ]);
  };
  return (
    <Drawer anchor={'right'} open={visible} onClose={handleClose}>
      <Box sx={{ width: 700 }}>
        <Box
          sx={{
            height: 'calc(100vh - 55px)',
            position: 'sticky',
            p: 4,
            overflow: 'auto',
          }}
        >
          <Box
            mb={5}
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Typography variant='h3'>{title} </Typography>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          {isCron && (
            <Box mt={4}>
              <Box component='p' color='text.primary' fontSize={14} mb={2}>
                Cron
              </Box>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    disabled={readonly}
                    type='string'
                    fullWidth
                    value={cronValue ?? ''}
                    error={!cronValue}
                    helperText={!cronValue && 'Cron is required'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton onClick={onShowSetCron}>
                            <ContentPasteSearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) => setCronValue(e.target.value)}
                  />
                </Grid>
              </Grid>

              <CronModal
                visible={showSetCron}
                handleClose={onCloseSetCron}
                handleSave={(value) => {
                  setCronValue(value);
                  onCloseSetCron();
                }}
              />
            </Box>
          )}

          <Box mt={4}>
            <CollapseCard
              readonly={readonly}
              label={`Environments`}
              environments={environments}
              setFieldValue={setFieldValue}
              deleteEnv={deleteEnv}
              addNewRow={addNewRow}
            />
          </Box>
        </Box>

        {!readonly && (
          <Box
            sx={{
              p: 2,
              borderTop: '1px solid #ddd',
              textAlign: 'right',
              '& .MuiButton-root': {
                ml: 2,
                mr: 2,
              },
            }}
          >
            <Button onClick={handleClose}> Cancel </Button>
            <Button
              variant='contained'
              onClick={() => {
                const isValidCron = isCron ? !!cronValue : true;
                setContainerApps({
                  environments,
                  cron: cronValue,
                  isValid: isValidCron && !environments.some((e) => !e.isValid),
                });
                handleClose();
              }}
            >
              Save
            </Button>
          </Box>
        )}
      </Box>
    </Drawer>
  );
};

export default React.memo(AddEnvironmentDrawer);

AddEnvironmentDrawer.propTypes = {};
