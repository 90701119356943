import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { getListAppInstanceQuery } from '../apis';
import { setFieldAppInstance } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';

const useGetListAppInstance = () => {
  const dispatch = useDispatch();

  const getListAppInstance = useQuery({
    queryKey: ['app-instance-list'],
    queryFn: getListAppInstanceQuery,
  });

  useEffect(() => {
    const data =
      typeof getListAppInstance !== 'undefined'
        ? getListAppInstance?.data?.data
        : [];

    if (data) {
      dispatch(
        setFieldAppInstance({
          field: 'list',
          value: deepClone(data),
        }),
      );
    }
  }, [getListAppInstance?.data, dispatch]);

  return getListAppInstance;
};

export default useGetListAppInstance;
