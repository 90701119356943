import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getDetailAppInstanceQuery } from '../apis';
import { setFieldAppInstance } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';

const useGetDetailAppInstance = (id) => {
  const dispatch = useDispatch();

  const getDetailAppInstance = useQuery({
    queryKey: ['app-instance-detail-' + id],
    queryFn: () => getDetailAppInstanceQuery(id),
    onSuccess: (data) => {
      dispatch(setFieldAppInstance({ field: 'list', value: deepClone(data) }));
    },
  });

  return getDetailAppInstance;
};

export default useGetDetailAppInstance;
