import React from 'react';
import AppGridContainer from '@tenant/core/AppGridContainer';

import FeatureStepWebMarketPlace from './FeatureStepWebMarketPlace';

const FeatureStep = ({ values, errors, setFieldValue }) => {
  return (
    <AppGridContainer>
      <FeatureStepWebMarketPlace
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
      />
    </AppGridContainer>
  );
};

export default React.memo(FeatureStep);

FeatureStep.propTypes = {};

function FeatureStepForMobileMarketPlace() {}
