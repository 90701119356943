import { createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  error: '',
  loading: false,
  isAppDrawerOpen: false,
  updatingContent: false,
  message: '',
  orderBy: {
    requestList: 0,
    tenantRequestList: 0,
    tenantList: 0,
    bundleOrders: 0,
    transactions: 0,
  },
  autoHideDuration: 3500,
  customContent: null,
};

// Create slice
const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    fetchStart(state) {
      state.error = '';
      state.message = '';
      state.loading = true;
    },
    fetchSuccess(state) {
      state.error = '';
      state.message = '';
      state.loading = false;
      state.updatingContent = false;
    },
    fetchError(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.message = '';
      state.updatingContent = false;
    },
    updatingContent(state) {
      state.error = '';
      state.message = '';
      state.updatingContent = true;
    },
    showMessage(state, action) {
      state.error = '';
      state.message = action.payload.message;
      state.autoHideDuration = action.payload.autoHideDuration;
      state.loading = false;
      state.updatingContent = false;
      state.customContent = action.payload.customContent || null;
    },
    hideMessage(state) {
      state.loading = false;
      state.error = '';
      state.message = '';
      state.updatingContent = false;
      state.customContent = null;
      state.autoHideDuration = 3500;
    },
    toggleAppDrawer(state) {
      state.isAppDrawerOpen = !state.isAppDrawerOpen;
    },
    updateOrderBy(state, action) {
      state.orderBy[action.payload.key] = action.payload.value;
    },
  },
});

// Export actions
export const {
  fetchStart,
  fetchSuccess,
  fetchError,
  updatingContent,
  showMessage,
  hideMessage,
  toggleAppDrawer,
  updateOrderBy,
} = commonSlice.actions;

// Export reducer
export default commonSlice.reducer;
