import React from 'react';
import Table from './components/Table';
import AppsContainer from '@tenant/core/AppsContainer';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import clsx from 'clsx';
import Detail from './components/Detail';
import { Link, useParams } from 'react-router-dom';
import { ROUTE_STRING } from 'constants/RouteString';

const ListApp = () => {
  const { appId } = useParams();

  return (
    <AppsContainer
      fullView
      title={
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography color='text.primary'>Developer</Typography>
          <Typography color='text.primary'><Link to={ROUTE_STRING.LIST_APP.LIST}>Apps</Link></Typography>
          {appId && <Typography color='text.primary'>{appId}</Typography>}
        </Breadcrumbs>
      }
    >
      <Box
        sx={{
          display: !appId ? 'block' : 'none',
          '& .MuiDataGrid-root': {
            border: 0,
          },
        }}
      >
        <Table />
      </Box>

      <Box
        sx={{
          transition: 'all 0.5s ease',
          transform: 'translateX(100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          opacity: 0,
          visibility: 'hidden',
          backgroundColor: 'background.paper',
          '&.show': {
            transform: 'translateX(0)',
            opacity: 1,
            visibility: 'visible',
          },
        }}
        className={clsx({
          show: appId,
        })}
      >
        <Detail />
      </Box>
    </AppsContainer>
  );
};

export default React.memo(ListApp);
