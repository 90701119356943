const reducer = {
  setFieldDockerImage(state, action) {
    const { field, value } = action.payload ?? {};
    if (field) {
      state[field] = value;
    }
  },
  addItem(state, action) {
    state.list.push(action.payload);
  },
  removeItem(state, action) {
    state.list = state.list.filter((e) => e.ImageId !== action.payload.ImageId);
  },
  viewItem(state, action) {
    state.viewItem = action.payload;
  },
};

export default reducer;
