import { createSlice } from '@reduxjs/toolkit';

const initialSettings = {
  navCollapsed: false,
  initialPath: '/',
};

const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettings,
  reducers: {
    toggleNavCollapsed(state) {
      state.navCollapsed = !state.navCollapsed;
    },
    setInitialPath(state, action) {
      state.initialPath = action.payload;
    },
    resetNavOnLocationChange(state) {
      state.navCollapsed = false;
    },
  },
});

export const { toggleNavCollapsed, setInitialPath, resetNavOnLocationChange } = settingsSlice.actions;

export default settingsSlice.reducer;