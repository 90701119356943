const reducers = {
  setFieldSelect(state, payload) {
    const { field, value } = payload ?? {};
    if (field) {
      state.field = value;
    }
  },
};

export default reducers;
