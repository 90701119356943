export default [
  {
    Value: 1,
    Description: 'Web App.',
    IsCron: false,
  },
  // {
  //   Value: 2,
  //   Description: 'Application Programming Interface (API).',
  //   IsCron: false,
  // },
  {
    Value: 3,
    Description: 'Scheduled Job Application.',
    IsCron: true,
  },
];
