import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { getListDockerImageQuery } from '../apis';
import { setFieldDockerImage } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';
import serializeListDockerImageModel from '../helpers/serializeListDockerImageModel';

const useGetListDockerImage = () => {
  const dispatch = useDispatch();

  const getListDockerImage = useQuery({
    queryKey: ['docker-image-list'],
    queryFn: getListDockerImageQuery,
  });

  useEffect(() => {
    const data =
      typeof getListDockerImage !== 'undefined' ? getListDockerImage?.data : [];
    if (data) {
      dispatch(
        setFieldDockerImage({
          field: 'list',
          value: deepClone(
            serializeListDockerImageModel(data?.data?.DockerImages),
          ),
        }),
      );
    }
  }, [getListDockerImage?.data, dispatch]);

  return getListDockerImage;
};

export default useGetListDockerImage;
