import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

const DeleteDialog = ({ visible, handleClose, handleSave, loading }) => {
  return (
    <Dialog open={visible} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle fontSize={16}>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-delete-dialog-description'>
          Warning: This action cannot be undone. Are you sure you want to delete
          this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          variant='contained'
          color='error'
          onClick={handleSave}
        >
          Delete
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(DeleteDialog);

DeleteDialog.propTypes = {
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSave: PropTypes.func,
};
