import { RoutePermittedRole } from 'constants/AppConst';
import { ROUTE_STRING } from 'constants/RouteString';
import List from './List';

export const appInstanceRoutes = [
  {
    permittedRole: RoutePermittedRole.User,
    path: [
      ROUTE_STRING.APP_INSTANCE.LIST,
      ROUTE_STRING.APP_INSTANCE.ADD,
      ROUTE_STRING.APP_INSTANCE.VIEW,
    ],
    element: <List />,
  },
];
