import React, { useMemo, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Chip,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material';
import { NavLink, useNavigate } from 'react-router-dom';
import { ROUTE_STRING } from 'constants/RouteString';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { PAGE_SIZE } from 'constants/Common';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppSearch from '@tenant/core/AppSearchBar';
import { useDebounce } from '@tenant/utility/hooks';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import HandymanIcon from '@mui/icons-material/Handyman';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import { StatusAppType } from 'constants/AppEnums';
import useGetListAppInstance from 'redux/slices/appInstance/hooks/useGetListAppInstance';
import DockerAppTypes from 'constants/DockerAppTypes';
import { useDispatch, useSelector } from 'react-redux';
import { removeItem, viewItem } from 'redux/slices/appInstance';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from '@tenant/utility/constants/default';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import useModal from '@tenant/utility/hooks/useModal';
import DeleteDialog from '@tenant/core/App/Dialog/DeleteDialog';
import useDeleteAppInstance from 'redux/slices/appInstance/hooks/useDeleteAppInstance';
import { deepClone } from '@mui/x-data-grid/utils/utils';

export const StatusApp = {
  [StatusAppType.IN_ACTIVE]: {
    label: 'In Active',
    color: 'error',
    icon: <CancelIcon />,
  },
  [StatusAppType.ACTIVE]: {
    label: 'Active',
    color: 'info',
    icon: <RocketLaunchIcon />,
  },
  [StatusAppType.COMING_SOON]: {
    label: 'Coming Soon',
    color: 'warning',
    icon: <HourglassEmptyIcon />,
  },
  [StatusAppType.DRAFT]: {
    label: 'Draft',
    color: 'warning',
    icon: <HandymanIcon />,
  },
};

const AppInstanceTable = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState();
  const { visible, onClose, onShow } = useModal();
  const { isLoading } = useGetListAppInstance();
  const { list } = useSelector((s) => s.appInstance);
  const totalCount = list?.length ?? 0;
  const navigate = useNavigate();
  const rows = useMemo(
    () =>
      list
        ?.slice(
          (pageIndex - 1) * DEFAULT_PAGE_SIZE,
          DEFAULT_PAGE_SIZE * pageIndex,
        )
        ?.filter(
          (e) => e.Name?.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0,
        ) ?? [],
    [searchTerm, list, pageIndex],
  );
  const dispatch = useDispatch();
  const deleteMutate = useDeleteAppInstance();

  const onViewItem = (item) => {
    dispatch(viewItem(item));
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
    setPageIndex(DEFAULT_PAGE_INDEX);
  };

  const handleCloseDelete = () => {
    setSelected();
    onClose();
  };
  const handleShowDelete = (item) => {
    setSelected(deepClone(item));
    onShow();
  };

  const handleSaveDelete = () => {
    deleteMutate.mutate(selected, {
      onSuccess() {
        dispatch(removeItem(selected));
        handleCloseDelete();
      },
    });
  };

  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      width: 400,
      sortable: true,
      flex: 1,
    },
    {
      field: 'AppPackageVersion',
      headerName: 'App Package Version',
      width: 175,
      sortable: false,
    },
    {
      field: 'CpuAndMemory',
      headerName: 'Cpu And Memory',
      width: 175,
      sortable: false,
      align: 'right',
      headerAlign: 'right',
    },

    {
      field: 'Action',
      headerName: '',
      width: 50,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ minWidth: 150 }}
          key='action--view'
          label='View'
          showInMenu
          onClick={() => {
            onViewItem(params.row);
            navigate(ROUTE_STRING.APP_INSTANCE.LIST + '/' + params.row.Id);
          }}
        />,
        <GridActionsCellItem
          key='action--delete'
          label='Delete'
          showInMenu
          onClick={() => {
            handleShowDelete(params.row);
          }}
        />,
      ],
    },
  ];

  const onPageChange = (value) => {
    setPageIndex(value);
  };

  const goToAdd = () => {
    navigate(ROUTE_STRING.APP_INSTANCE.ADD);
  };

  return (
    <>
      <AppsHeader>
        <Box
          flex={1}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <AppSearch
              isClearable
              searchValue={searchTerm}
              onClearSearch={clearSearchTerm}
              iconPosition='right'
              id='outlined-adornment-weight'
              size='small'
              overlap={false}
              onChange={onChangeSearch}
            ></AppSearch>
          </Box>
          <Button variant='outlined' onClick={goToAdd}>
            Add New
          </Button>
        </Box>
      </AppsHeader>
      <Box style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
        <DeleteDialog
          visible={visible}
          loading={deleteMutate?.isPending}
          handleClose={handleCloseDelete}
          handleSave={handleSaveDelete}
        />

        <DataGrid
          hideFooter
          disableColumnSelector
          disableColumnMenu
          rows={rows}
          columns={columns}
          getRowId={(row) => row.Id}
          loading={isLoading}
        />
      </Box>

      <FooterWrapper className='footer'>
        <Box justifyContent={'center'} className='footerContainer'>
          <Pagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 1}
            page={pageIndex}
            onChange={(e, v) => onPageChange(v)}
            showFirstButton
            showLastButton
          />
          <Typography>Total: {totalCount || 0}</Typography>
        </Box>
      </FooterWrapper>
    </>
  );
};

export default AppInstanceTable;
