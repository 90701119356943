import jwtAxios from '@tenant/services/auth/jwt-auth';

export const DOCKER_IMAGE_APIS = {
  ROOT: 'api/docker-images',
  GROUP_BY_NAME: 'api/docker-images/group-by-name',
};

export const getListDockerImageQuery = () => {
  return jwtAxios.get(DOCKER_IMAGE_APIS.ROOT);
};

export const getListGroupByNameDockerImageQuery = () => {
  return jwtAxios.get(DOCKER_IMAGE_APIS.GROUP_BY_NAME);
};

export const addDockerImageQuery = (payload) => {
  return jwtAxios.post(DOCKER_IMAGE_APIS.ROOT, payload);
};

export const deleteDockerImageQuery = (payload) => {
  return jwtAxios.delete(DOCKER_IMAGE_APIS.ROOT + '/' + payload?.ImageId);
};
