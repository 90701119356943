import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as yup from 'yup';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import CronModal from '@tenant/core/App/Cron/CronModal';
import useModal from '@tenant/utility/hooks/useModal';
import useUpdateCron from 'redux/slices/containerApp/hooks/useUpdateCron';
import { LoadingButton } from '@mui/lab';

const validationSchema = yup.object({
  Cron: yup.string().required().label('Cron'),
});

const UpdateCronDialog = ({
  visible,
  handleClose,
  initialValues,
  appInstanceId,
  containerAppId,
}) => {
  const {
    visible: visibleCron,
    onClose: onCloseCron,
    onShow: onShowCron,
  } = useModal();

  const updateCronMutate = useUpdateCron();

  return (
    <Dialog open={visible} onClose={handleClose} maxWidth='sm' fullWidth>
      <DialogTitle fontSize={16}>Edit Cron</DialogTitle>

      <Formik
        validateOnChange
        validateOnBlur
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(data, { setSubmitting }) => {
          updateCronMutate.mutate(
            {
              appInstanceId,
              containerAppId,
              Cron: data.Cron,
            },
            {
              onSuccess() {
                handleClose();
              },
            },
          );
        }}
        enableReinitialize
      >
        {({ values, setFieldValue, submitForm, errors }) => (
          <>
            <DialogContent>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={12}>
                  <Box component='p' color='text.primary' fontSize={14} mb={2}>
                    Cron
                    <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                      *
                    </Box>
                  </Box>
                  <Paper
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      boxShadow: 'none',
                      justifyContent: 'space-between',
                    }}
                  >
                    <TextField
                      value={values.Cron ?? ''}
                      error={!!errors.Cron}
                      helperText={errors.Cron}
                      sx={{ ml: 1, flex: 1 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              title='Select Cron'
                              type='button'
                              onClick={onShowCron}
                            >
                              <ContentPasteSearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Paper>

                  <CronModal
                    visible={visibleCron}
                    value={values.Cron}
                    handleClose={onCloseCron}
                    handleSave={(value) => {
                      setFieldValue('Cron', value);
                      onCloseCron();
                    }}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                loading={updateCronMutate?.isPending}
                onClick={submitForm}
                variant='contained'
              >
                Save
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(UpdateCronDialog);

UpdateCronDialog.propTypes = {};
