import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editApp } from 'api/appManager';
import { LIST_APP_DETAIL_QUERY, LIST_APP_QUERY } from 'constants/QueryString';

const useEditApp = () => {
  const queryClient = useQueryClient()

  const {
    data,
    error,
    isError,
    isIdle,
    isPending,
    isPaused,
    isSuccess,
    failureCount,
    failureReason,
    mutate,
    mutateAsync,
    reset,
    status,
    submittedAt,
    variables,
  } = useMutation({
    mutationFn: editApp,
    onSuccess: (data) => {
      queryClient.invalidateQueries([LIST_APP_DETAIL_QUERY, data.Id]);
      queryClient.invalidateQueries([LIST_APP_QUERY]);
    },
    onError: (error) => {
      console.error('Error creating user:', error);
      // Handle the error, show error message, etc.
    },
  });
  return {
    data,
    error,
    isError,
    isIdle,
    isPending,
    isPaused,
    isSuccess,
    failureCount,
    failureReason,
    mutate,
    mutateAsync,
    reset,
    status,
    submittedAt,
    variables,
  };
};

export default useEditApp;
