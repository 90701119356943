import React from 'react';
import PropTypes from 'prop-types';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import { Box } from '@mui/material';
import AppTooltip from '@tenant/core/AppTooltip';
import IntlMessages from '@tenant/utility/IntlMessages';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const EditLayout = ({ onClickBackButton }) => {
  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
          </Box>
        </Box>
      </AppsHeader>

      <Box p={4}></Box>
    </>
  );
};

export default React.memo(EditLayout);

EditLayout.propTypes = {};
