import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { addAppInstanceQuery } from '../apis';
import { addItem } from '..';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

const useAddAppInstance = () => {
  const dispatch = useDispatch();

  const addAppInstance = useMutation({
    mutationFn: addAppInstanceQuery,
    onSuccess: (data) => {
      toast.success('Add App Package successfully');
      dispatch(addItem(data));
    },
    onError: (error) => {
      const message = error?.response?.data?.title ?? 'Server error';
      toast.error(message);
      console.error('Error add app package:', error);
    },
  });

  return addAppInstance;
};

export default useAddAppInstance;
