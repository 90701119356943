import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import AppsContainer from '@tenant/core/AppsContainer';
import { ROUTE_STRING } from 'constants/RouteString';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import { TabContext, TabPanel } from '@mui/lab';
import useGetDetailContainerApp from 'redux/slices/containerApp/hooks/useGetDetailContainerApp';
import StartAndStopProgress from './Common/StartAndStopProgress';
import ContainerAppDescription from './Detail/ContainerAppDescription';
import EnvironmentVariablesDescription from './Detail/EnvironmentVariablesDescription';
import Logs from './Detail/Tab/Logs';
import clsx from 'clsx';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { grey } from '@mui/material/colors';
import EditLayout from './Edit/EditLayout';
import AppLoader from '@tenant/core/AppLoader';
import Error404 from 'pages/errorPages/Error404';
import Executions from './Detail/Tab/Executions';
import DockerAppTypes from 'constants/DockerAppTypes';
import AppAccordion from '@tenant/core/App/Accordion/AppAccordion';

const Detail = () => {
  const [value, setValue] = useState('Detail');
  const [isEdit, setIsEdit] = useState(false);
  const { appInstanceId, id: containerAppId } = useParams();
  const { data, isLoading, isError, refetch } = useGetDetailContainerApp({
    appInstanceId,
    containerAppId,
  });
  const isShowExecutions = useMemo(
    () =>
      !!data?.data?.DeploymentType &&
      DockerAppTypes.find(
        (e) => e.Value.toString() === data.data.DeploymentType.toString(),
      )?.IsCron,
    [data?.data?.DeploymentType],
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return isError ? (
    <Error404 />
  ) : (
    <AppsContainer
      fullView
      title={
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography color='text.primary'>Library</Typography>
          <Typography color='text.primary'>
            <Link
              to={ROUTE_STRING.APP_INSTANCE.VIEW.replace(':id', appInstanceId)}
            >
              App Instances
            </Link>
          </Typography>
          <Typography color='text.primary'>Container App</Typography>
        </Breadcrumbs>
      }
      sxStyle={{
        '& .apps-header': {
          height: 50,
        },
      }}
    >
      {isLoading && <AppLoader />}

      <TabContext value={value.toString()}>
        <Box
          sx={{
            visibility: 'visible',
            '& .MuiDataGrid-root': {
              border: 0,
            },
          }}
        >
          <AppsHeader>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  sx={{ width: '100%' }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label='basic tabs example'
                  >
                    <Tab label='Detail' value='Detail' />
                    <Tab
                      label='Environment Variables'
                      value='Environment Variables'
                    />
                    {isShowExecutions && (
                      <Tab label='Job Executions' value='Job Executions' />
                    )}
                    <Tab label='Logs' value='Logs' />
                  </Tabs>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                display={'flex'}
                alignItems={'center'}
                justifyContent={'end'}
                sx={{
                  '& .MuiButtonBase-root': {
                    ml: 2,
                    mr: 2,
                  },
                }}
              >
                {!!data?.data?.Status && (
                  <StartAndStopProgress
                    status={data?.data?.Status}
                    appInstanceId={appInstanceId}
                    containerAppId={containerAppId}
                    callBack={refetch}
                  />
                )}
              </Grid>
            </Grid>
          </AppsHeader>
          <Box>
            <TabPanel value='Detail'>
              <AppAccordion title='Essentials'>
                <ContainerAppDescription
                  detail={data?.data ?? {}}
                  appInstanceId={appInstanceId}
                  containerAppId={containerAppId}
                />
              </AppAccordion>
            </TabPanel>
            <TabPanel value='Environment Variables'>
              <EnvironmentVariablesDescription
                environmentVariables={data?.data?.EnviromentVariables}
                appInstanceId={appInstanceId}
                containerAppId={containerAppId}
                refetchDetail={refetch}
              />
            </TabPanel>
            <TabPanel value='Job Executions'>
              {isShowExecutions && <Executions />}
            </TabPanel>
            <TabPanel value='Logs'>
              <Logs />
            </TabPanel>
          </Box>
        </Box>
      </TabContext>

      <Box
        sx={{
          transition: 'all 0.5s ease',
          transform: 'translateX(100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          opacity: 0,
          visibility: 'hidden',
          backgroundColor: 'background.paper',
          '&.show': {
            transform: 'translateX(0)',
            opacity: 1,
            visibility: 'visible',
          },
        }}
        className={clsx({
          show: isEdit,
        })}
      >
        {isEdit && <EditLayout onClickBackButton={() => setIsEdit(false)} />}
      </Box>
    </AppsContainer>
  );
};

export default React.memo(Detail);

Detail.propTypes = {};
