
export const initialValuesStepInformation = {
  Name: '',
  ShortDescription: '',
  LongDescription: '',
  AppUrl: '',
  KeyBenefits: '',
  KeyLabels: '',
  Platform: 1,
  LaunchType: 1,
  IntegrationMode: 1,
  IsLegacy: true,
  IconBlobReference: '',
  AuthorId: null,
  CreatedBy: '',
  Status: 1,
  AppVersionNumber: '1.0.0',
  ChangeLog: '',
  CategoryIds: [],
  Dependencies: 'Core Joblogic System',
  AuthenticationType: 0,
  PriceType: 0,
  PageSlots: [],
  EmbeddedUrl: '',
  // Mobile fields
  AppConnectivityState: '',
  MobileAppCode: '',
  BundleZipFile: null,
  BundleZipBlobReference: '',
  MobileBAUModuleIDs: [],
  MobileFeatureUrl: ''
};
