import moment from 'moment';
import {STATISTIC_DATE_GROUP_TYPE} from './enum';

export const inputFormat = 'DD/MM/YYYY';
export const inputFormatDateTime = 'DD/MM/YYYY HH:mm:ss';

export const dateRanges = {
  'This week': [moment().startOf('week'), moment().endOf('week')],
  'Last week': [
    moment().subtract(1, 'week').startOf('week'),
    moment().subtract(1, 'week').endOf('week'),
  ],
  'This month': [moment().startOf('month'), moment().endOf('month')],
  'Last month': [
    moment().subtract(1, 'month').startOf('month'),
    moment().subtract(1, 'month').endOf('month'),
  ],
  'This year': [moment().startOf('year'), moment().endOf('year')],
  'Last year': [
    moment().subtract(1, 'year').startOf('year'),
    moment().subtract(1, 'year').endOf('year'),
  ],
};

export const yearRanges = {
  'This year': [moment().startOf('year'), moment().endOf('year')],
  'Last year': [
    moment().subtract(1, 'year').startOf('year'),
    moment().subtract(1, 'year').endOf('year'),
  ],
};

export const dateGroupTypes = {
  'This week': STATISTIC_DATE_GROUP_TYPE.Day,
  'Last week': STATISTIC_DATE_GROUP_TYPE.Day,
  'This month': STATISTIC_DATE_GROUP_TYPE.Day,
  'Last month': STATISTIC_DATE_GROUP_TYPE.Day,
  'This year': STATISTIC_DATE_GROUP_TYPE.Month,
  'Last year': STATISTIC_DATE_GROUP_TYPE.Month,
  '5 year': STATISTIC_DATE_GROUP_TYPE.Year,
  'Last 7 days': STATISTIC_DATE_GROUP_TYPE.Day,
  'Last 30 days': STATISTIC_DATE_GROUP_TYPE.Day,
  'This quarter': STATISTIC_DATE_GROUP_TYPE.Month,
  'Last quarter': STATISTIC_DATE_GROUP_TYPE.Month,
  'Last 6 months': STATISTIC_DATE_GROUP_TYPE.Month,
};

export const DEFAULT_PAGE_INDEX = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_DATE_RANGE = 'This month';

export const dateFormatByKey = {
  'Last 7 days': {
    value: [
      moment().subtract(7, 'days').format(inputFormat),
      moment().format(inputFormat),
    ],
    description: `${moment().subtract(7, 'days').format('MMM')} ${moment()
      .subtract(7, 'days')
      .format('DD')}-${moment().format('MMM')} ${moment().format('DD')}`,
    key: 'days',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Relative dates',
    text: 'Last 7 days',
  },
  'This week': {
    value: [
      moment().startOf('week').format(inputFormat),
      moment().endOf('week').format(inputFormat),
    ],
    description: `${moment().startOf('week').format('MMM')} ${moment()
      .startOf('week')
      .format('DD')}-${moment().endOf('week').format('MMM')} ${moment()
      .endOf('week')
      .format('DD')}`,
    key: 'days',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Relative dates',
    text: 'This week',
  },
  'Last week': {
    value: [
      moment().subtract(1, 'week').startOf('week').format(inputFormat),
      moment().subtract(1, 'week').endOf('week').format(inputFormat),
    ],
    description: `${moment()
      .subtract(1, 'week')
      .startOf('week')
      .format('MMM')} ${moment()
      .subtract(1, 'week')
      .startOf('week')
      .format('DD')}-${moment()
      .subtract(1, 'week')
      .endOf('week')
      .format('MMM')} ${moment()
      .subtract(1, 'week')
      .endOf('week')
      .format('DD')}`,
    key: 'days',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Relative dates',
    text: 'Last week',
  },
  'This month': {
    value: [
      moment().startOf('month').format(inputFormat),
      moment().endOf('month').format(inputFormat),
    ],
    description: `${moment().format('MMM YYYY')}`,
    key: 'month',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Calendar months',
    text: 'This month',
  },
  'Last 30 days': {
    value: [
      moment().subtract(30, 'days').format(inputFormat),
      moment().format(inputFormat),
    ],
    description: `${moment()
      .subtract(30, 'days')
      .format('MMM DD')}-${moment().format('MMM DD')}`,
    key: 'month',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Relative dates',
    text: 'Last 30 days',
  },
  'This quarter': {
    value: [
      moment().startOf('quarter').format(inputFormat),
      moment().endOf('quarter').format(inputFormat),
    ],
    description: `${moment().startOf('quarter').format('MMM')}-${moment()
      .endOf('quarter')
      .format('MMM')} ${moment().endOf('quarter').format('YYYY')}`,
    key: 'month',
    groupType: STATISTIC_DATE_GROUP_TYPE.Month,
    title: 'Calendar months',
    text: 'This quarter',
  },
  'This year': {
    value: [
      moment().startOf('year').format(inputFormat),
      moment().endOf('year').format(inputFormat),
    ],
    description: `${moment().format('YYYY')}`,
    key: 'year',
    groupType: STATISTIC_DATE_GROUP_TYPE.Month,
    title: 'Calendar months',
    text: 'This year',
  },
  'Last month': {
    value: [
      moment().subtract(1, 'months').startOf('month').format(inputFormat),
      moment().subtract(1, 'months').endOf('month').format(inputFormat),
    ],
    description: `${moment().subtract(1, 'months').format('MMM YYYY')}`,
    key: 'month',
    groupType: STATISTIC_DATE_GROUP_TYPE.Day,
    title: 'Calendar months',
    text: 'Last month',
  },
  'Last quarter': {
    value: [
      moment().subtract(1, 'quarter').startOf('quarter').format(inputFormat),
      moment().subtract(1, 'quarter').endOf('quarter').format(inputFormat),
    ],
    description: `${moment()
      .subtract(1, 'quarter')
      .startOf('quarter')
      .format('MMM')}-${moment()
      .subtract(1, 'quarter')
      .endOf('quarter')
      .format('MMM')} ${moment()
      .subtract(1, 'quarter')
      .endOf('quarter')
      .format('YYYY')}`,
    key: 'month',
    groupType: STATISTIC_DATE_GROUP_TYPE.Month,
    title: 'Calendar months',
    text: 'Last quarter',
  },
  'Last 6 months': {
    value: [
      moment().subtract(7, 'months').startOf('month').format(inputFormat),
      moment().subtract(1, 'months').endOf('month').format(inputFormat),
    ],
    description: `${moment().subtract(7, 'months').format('MMM')}-${moment()
      .subtract(1, 'months')
      .format('MMM')} ${moment().subtract(7, 'months').format('YYYY')}`,
    key: 'month',
    groupType: STATISTIC_DATE_GROUP_TYPE.Month,
    title: 'Calendar months',
    text: 'Last 6 months',
  },
  'Last year': {
    value: [
      moment().subtract(1, 'year').startOf('year').format(inputFormat),
      moment().subtract(1, 'year').endOf('year').format(inputFormat),
    ],
    description: `${moment().subtract(1, 'year').endOf('year').format('YYYY')}`,
    key: 'year',
    groupType: STATISTIC_DATE_GROUP_TYPE.Month,
    title: 'Calendar months',
    text: 'Last year',
  },
};

export const RADIAN = Math.PI / 180;
