import React, { useMemo, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Button, IconButton, Pagination, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_STRING } from 'constants/RouteString';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { PAGE_SIZE } from 'constants/Common';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppSearch from '@tenant/core/AppSearchBar';
import useGetListAppPackage from 'redux/slices/appPackage/hooks/useGetListAppPackage';
import { useDispatch, useSelector } from 'react-redux';
import { removeItem } from 'redux/slices/appPackage';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
  inputFormatDateTime,
} from '@tenant/utility/constants/default';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteDialog from '@tenant/core/App/Dialog/DeleteDialog';
import useModal from '@tenant/utility/hooks/useModal';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import useDeleteAppPackage from 'redux/slices/appPackage/hooks/useDeleteAppPackage';

const AppPackageTable = () => {
  const [pageIndex, setPageIndex] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState();
  const { isLoading } = useGetListAppPackage();
  const deleteMutate = useDeleteAppPackage();
  const { list } = useSelector((s) => s.appPackage);
  const totalCount = list?.length ?? 0;
  const navigate = useNavigate();
  const { visible, onClose, onShow } = useModal();
  const rows = useMemo(
    () =>
      list
        ?.slice(
          (pageIndex - 1) * DEFAULT_PAGE_SIZE,
          DEFAULT_PAGE_SIZE * pageIndex,
        )
        ?.filter(
          (e) => e.Name?.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0,
        ) ?? [],
    [searchTerm, list, pageIndex],
  );
  const dispatch = useDispatch();

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
    setPageIndex(DEFAULT_PAGE_INDEX);
  };

  const onPageChange = (value) => {
    setPageIndex(value);
  };

  const goToAdd = () => {
    navigate(ROUTE_STRING.APP_PACKAGE.ADD);
  };

  const handleCloseDelete = () => {
    setSelected();
    onClose();
  };
  const handleShowDelete = (item) => {
    setSelected(deepClone(item));
    onShow();
  };
  const handleSaveDelete = () => {
    deleteMutate.mutate(selected, {
      onSuccess() {
        dispatch(removeItem(selected));
        handleCloseDelete();
      },
    });
  };

  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      width: 400,
      sortable: true,
      flex: 1,
    },
    {
      field: 'Version',
      headerName: 'Version',
      width: 125,
      sortable: false,
    },
    {
      field: 'CreatedAt',
      headerName: 'Created At',
      width: 150,
      sortable: true,
      renderCell: (params) => (
        <Box
          component={'span'}
          sx={{ width: '100%', display: 'block' }}
          className='MuiDataGrid-cellContent'
        >
          {params.value
            ? formatDateFromISO(params.value, inputFormatDateTime)
            : ''}
        </Box>
      ),
    },
    {
      field: 'Action',
      headerName: '',
      width: 50,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ minWidth: 150 }}
          key='action--view'
          label='View'
          showInMenu
          onClick={() => {
            navigate(ROUTE_STRING.APP_PACKAGE.LIST + '/' + params.row.Id);
          }}
        />,
        <GridActionsCellItem
          key='action--delete'
          label='Delete'
          showInMenu
          onClick={() => {
            handleShowDelete(params.row);
          }}
        />,
      ],
    },
  ];

  return (
    <>
      <AppsHeader>
        <Box
          flex={1}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <AppSearch
              isClearable
              searchValue={searchTerm}
              onClearSearch={clearSearchTerm}
              iconPosition='right'
              id='outlined-adornment-weight'
              size='small'
              overlap={false}
              onChange={onChangeSearch}
            ></AppSearch>
          </Box>
          <Button variant='outlined' onClick={goToAdd}>
            Add New
          </Button>
        </Box>
      </AppsHeader>

      <DeleteDialog
        visible={visible}
        loading={deleteMutate?.isPending}
        handleClose={handleCloseDelete}
        handleSave={handleSaveDelete}
      />

      <Box style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
        <DataGrid
          hideFooter
          disableColumnSelector
          disableColumnMenu
          rows={rows}
          columns={columns}
          getRowId={(row) => row.Id}
          loading={isLoading}
        />
      </Box>

      <FooterWrapper className='footer'>
        <Box justifyContent={'center'} className='footerContainer'>
          <Pagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 1}
            page={pageIndex}
            onChange={(e, v) => onPageChange(v)}
            showFirstButton
            showLastButton
          />
          <Typography>Total: {totalCount || 0}</Typography>
        </Box>
      </FooterWrapper>
    </>
  );
};

export default AppPackageTable;
