import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { getDetailContainerAppQuery } from '../apis';
import { setFieldContainerApp } from '..';
import { deepClone } from '@tenant/utility/helper/Utils';
import { useDispatch } from 'react-redux';

const useGetDetailContainerApp = ({ appInstanceId, containerAppId }) => {
  const dispatch = useDispatch();

  const getData = useQuery({
    queryKey: ['container-app-detail' + appInstanceId + '' + containerAppId],
    queryFn: () =>
      getDetailContainerAppQuery({ appInstanceId, containerAppId }),
  });

  useEffect(() => {
    const data = typeof getData !== 'undefined' ? getData?.data?.data : [];

    if (data) {
      dispatch(
        setFieldContainerApp({
          field: 'list',
          value: deepClone(data),
        }),
      );
    }
  }, [getData?.data, dispatch]);

  return getData;
};

export default useGetDetailContainerApp;
