import React from 'react';
import { RoutePermittedRole } from 'constants/AppConst';
import Steps from './components/Steps';
import { ROUTE_STRING } from 'constants/RouteString';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import { initialValuesStepInformation } from 'pages/publishApp/helpers/initialValues';
import { useNavigate } from 'react-router-dom';
import useCreateApp from './hooks/useCreateApp';
import AppLoader from '@tenant/core/AppLoader';
import AppsContainer from '@tenant/core/AppsContainer';
import { ACTION_MODE_ENUM } from 'constants/AppEnums';

const PublishApp = () => {
  const navigate = useNavigate();
  const { mutate, isPending, isSuccess } = useCreateApp();

  const handleSubmit = (data) => {
    mutate(data, {
      onSuccess: () => {
        navigate(ROUTE_STRING.LIST_APP.LIST);
      },
    });
  };

  return (
    <AppsContainer
      fullView
      title={
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography color='text.primary'>Developer</Typography>
          <Typography color='text.primary'>Create App</Typography>
        </Breadcrumbs>
      }
    >
      <Box sx={{ p: 4 }}>
        {isPending && <AppLoader />}

        <Steps
          mode={ACTION_MODE_ENUM['Create']}
          input={initialValuesStepInformation}
          submit={handleSubmit}
          isPending={isPending}
          isSuccess={isSuccess}
        />
      </Box>
    </AppsContainer>
  );
};

export default React.memo(PublishApp);

export const publishAppRoutes = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ROUTE_STRING.PUBLISH_APP,
    element: <PublishApp />,
  },
];
