import React, { useState } from 'react';
import AppsContainer from '@tenant/core/AppsContainer';
import { Box, Breadcrumbs, Typography } from '@mui/material';
import clsx from 'clsx';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ROUTE_STRING } from 'constants/RouteString';
import AppInstanceTable from './components/List/AppInstanceTable';
import Add from './Add';
import { useSelector } from 'react-redux';
import Detail from './Detail';

const ListAppInstance = () => {
  const location = useLocation();
  const { id } = useParams();
  const isAddRoute = location.pathname === ROUTE_STRING.APP_INSTANCE.ADD;
  const isView = !!id;
  const { viewItem } = useSelector((s) => s.appInstance);

  return (
    <AppsContainer
      fullView
      title={
        <Breadcrumbs aria-label='breadcrumb'>
          <Typography color='text.primary'>Library</Typography>
          <Typography color='text.primary'>
            <Link to={ROUTE_STRING.APP_INSTANCE.LIST}>App Instances</Link>
          </Typography>
          {isAddRoute && <Typography color='text.primary'>Add</Typography>}
        </Breadcrumbs>
      }
    >
      <Box
        sx={{
          visibility: !isAddRoute && !isView ? 'visible' : 'hidden',
          '& .MuiDataGrid-root': {
            borderLeft: 0,
            borderRight: 0,
            borderTop: 0,
            borderRadius: 0,
          },
        }}
      >
        <AppInstanceTable />
      </Box>

      <Box
        sx={{
          transition: 'all 0.5s ease',
          transform: 'translateX(100%)',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 1,
          opacity: 0,
          visibility: 'hidden',
          backgroundColor: 'background.paper',
          '&.show': {
            transform: 'translateX(0)',
            opacity: 1,
            visibility: 'visible',
          },
        }}
        className={clsx({
          show: isAddRoute || isView,
        })}
      >
        {!isView ? <Add /> : <Detail item={viewItem ?? {}} />}
      </Box>
    </AppsContainer>
  );
};

export default React.memo(ListAppInstance);
