import React from 'react';
import { Provider } from 'react-redux';
import CssBaseline from '@mui/material/CssBaseline';
import AuthRoutes from '@tenant/utility/AuthRoutes';
import AppContextProvider from '@tenant/utility/AppContextProvider';
import AppThemeProvider from '@tenant/utility/AppThemeProvider';
import AppStyleProvider from '@tenant/utility/AppStyleProvider';
import AppLocaleProvider from '@tenant/utility/AppLocaleProvider';
import AppLayout from '@tenant/core/AppLayout';
import store from 'redux/store';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { SnackbarProvider } from 'notistack';
import FirebaseAuthProvider from '@tenant/services/auth/firebase/FirebaseAuthProvider';
import QueryClientProvider from 'services/QueryClientProvider';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';

const App = ({ instance }) => (
  <QueryClientProvider>
    <AppContextProvider>
      <Provider store={store}>
        <AppThemeProvider>
          <AppStyleProvider>
            <AppLocaleProvider>
              <BrowserRouter>
                <FirebaseAuthProvider>
                  <MsalProvider instance={instance}>
                    <AuthRoutes>
                      <CssBaseline />
                      <AppLayout />
                      <ToastContainer
                        position='bottom-right'
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                      />
                    </AuthRoutes>
                  </MsalProvider>
                </FirebaseAuthProvider>
              </BrowserRouter>
            </AppLocaleProvider>
          </AppStyleProvider>
        </AppThemeProvider>
      </Provider>
    </AppContextProvider>
  </QueryClientProvider>
);

export default App;
