import API_STRINGS from 'constants/Api';
import jwtAxios from '@tenant/services/auth/jwt-auth';

export const getRatingApps = async (query) => {
  const response = await jwtAxios.get(API_STRINGS.RATING_MANAGER.GET_LIST, {
    params: query,
  });
  return response.data;
};

export const toggleCommentVisibility = async (query) => {
  const response = await jwtAxios.patch(
    API_STRINGS.RATING_MANAGER.TOGGLE_COMMENT_VISIBILITY(query.id),
    query,
  );
  return response.data;
};
