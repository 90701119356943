import React, { useEffect, useState } from 'react';
import { IconButton, keyframes } from '@mui/material';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppTooltip from '../AppTooltip';
import { alpha } from '@mui/material/styles';

const bellRing = keyframes`
  0%,
  100% {
    transform-origin: top;
  }

  15% {
    transform: rotateZ(10deg);
  }

  30% {
    transform: rotateZ(-10deg);
  }

  45% {
    transform: rotateZ(5deg);
  }

  60% {
    transform: rotateZ(-5deg);
  }

  75% {
    transform: rotateZ(2deg);
  }

`;

const AppNotifications = ({
  drawerPosition = 'right',
  tooltipPosition = 'bottom',
  isMenu,
  sxNotificationContentStyle,
}) => {
  const [showNotification, setShowNotification] = useState(false);

  return (
    <>
      {isMenu ? (
        <Box component='span' onClick={() => setShowNotification(true)}></Box>
      ) : (
        <AppTooltip title='Notification' placement={tooltipPosition}>
          <IconButton
            className='icon-btn'
            sx={{
              borderRadius: '50%',
              width: 40,
              height: 40,
              color: (theme) => theme.palette.text.secondary,
              backgroundColor: (theme) => theme.palette.background.default,
              border: 1,
              borderColor: 'transparent',
              '&:hover, &:focus': {
                color: (theme) => theme.palette.text.primary,
                backgroundColor: (theme) =>
                  alpha(theme.palette.background.default, 0.9),
                borderColor: (theme) =>
                  alpha(theme.palette.text.secondary, 0.25),
              },
              '&:hover svg': {
                animation: `${bellRing} 0.9s both`,
              },
            }}
            onClick={() => setShowNotification(true)}
            size='large'
          ></IconButton>
        </AppTooltip>
      )}

      <Drawer
        PaperProps={{
          sx: { minWidth: 400 },
        }}
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => setShowNotification(false)}
      ></Drawer>
    </>
  );
};

export default AppNotifications;

AppNotifications.propTypes = {
  drawerPosition: PropTypes.string,
  tooltipPosition: PropTypes.string,
  isMenu: PropTypes.bool,
  sxNotificationContentStyle: PropTypes.object,
};
