import { Box, Button, Grid, Input, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const AppZipFileInput = ({
  defaultFile,
  onChange = () => {},
  error,
  helperText,
  appUrl,
}) => {
  const [zipFile, setZipFile] = useState(defaultFile);
  const [zipFileName, setZipFileName] = useState(defaultFile?.name);

  useEffect(() => {
    if (defaultFile) {
      setZipFile(URL.createObjectURL(defaultFile));
      setZipFileName(defaultFile.name);
    }
  }, []);

  useEffect(() => {
    if (appUrl) {
      setZipFileName(appUrl);
    }
  }, [appUrl]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setZipFile(URL.createObjectURL(file));
      setZipFileName(file.name);
      onChange(file);
    }
  };

  return (
    <>
      <Grid>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 5,
            border: error ? '2px dashed #d32f2f' : '2px dashed #1976d2',
            backgroundColor: '#f5f5f5',
            width: '100%',
            height: '100%',
            margin: 'auto',
            borderRadius: 2,
          }}
        >
          {!zipFile && !appUrl && (
            <Typography variant='body1' color='textSecondary' mb={2}>
              No zip file selected (accept format: .zip)
            </Typography>
          )}

          {zipFileName && (
            <Typography
              variant='body1'
              color='textSecondary'
              mb={2}
              sx={{
                wordWrap: 'break-word',
                width: '100%',
                textAlign: 'center',
              }}
            >
              {zipFileName}
            </Typography>
          )}

          <label htmlFor='zip-file-upload'>
            <Input
              id='zip-file-upload'
              type='file'
              inputProps={{
                accept: '.zip',
              }}
              onChange={handleFileChange}
              sx={{ display: 'none' }}
            ></Input>

            <Button
              variant='contained'
              component='span'
              startIcon={<CloudUploadIcon />}
              color='primary'
            >
              {'Upload Zip File'}
            </Button>
          </label>
        </Box>
      </Grid>

      {!!helperText && (
        <Grid
          color={error && 'error.main'}
          item
          xs={12}
          sm={12}
          md={12}
          mt={2}
          sx={{ fontSize: '0.75rem' }}
        >
          {helperText}
        </Grid>
      )}
    </>
  );
};

export default React.memo(AppZipFileInput);
