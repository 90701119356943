import { createSlice } from '@reduxjs/toolkit';
import reducer from './reducer';

const initialState = {
  list: [],
  log: {},
  jobExecutions: [],
  viewItem: undefined,
};

export default createSlice({
  name: 'selects',
  initialState: initialState,
  reducers: reducer,
});
