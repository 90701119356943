import jwtAxios from '@tenant/services/auth/jwt-auth';

export const APP_PACKAGE_APIS = {
  ROOT: 'api/app-packages',
};

export const getListAppPackageQuery = () => {
  return jwtAxios.get(APP_PACKAGE_APIS.ROOT);
};

export const getDetailAppPackageQuery = (id) => {
  return jwtAxios.get(APP_PACKAGE_APIS.ROOT + '/' + id);
};

export const addAppPackageQuery = (payload) => {
  return jwtAxios.post(APP_PACKAGE_APIS.ROOT, payload);
};

export const deleteAppPackageQuery = (payload) => {
  return jwtAxios.delete(APP_PACKAGE_APIS.ROOT + '/' + payload.Id);
};

export const upgradeVersionAppPackageQuery = (payload) => {
  return jwtAxios.put(
    APP_PACKAGE_APIS.ROOT +
      '/' +
      payload.Id +
      ':upgrade-to-latest-image-versions',
  );
};
