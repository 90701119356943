export default [
  {
    Value: 'hybrid',
    Description: 'Hybrid Flow',
  },
  {
    Value: 'client_credentials',
    Description: 'Client Credentials Flow',
  },
  {
    Value: 'authorization_code',
    Description: 'Authorization Code Flow with PKCE',
  },
];
