import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import IdpClientEnum from 'constants/IdpClientEnum';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';
import { LoadingButton } from '@mui/lab';
import { grey } from '@mui/material/colors';

const validationSchema = Yup.object().shape({
  ClientName: Yup.string().required().max(255).label('Client Name'),
  AuthenticationFlow: Yup.object().required().label('Flow'),
});

const IdpClientModalForm = ({
  initialValues,
  appInstanceId,
  visible,
  handleClose,
  handleSubmit,
  loading,
  isEdit,
}) => {
  const checkActiveClientSecret = (flow) => {
    return flow !== 'authorization_code';
  };

  return (
    <Dialog open={visible} onClose={handleClose} maxWidth='md' fullWidth>
      <DialogTitle fontSize={16}>
        {isEdit ? 'Edit Clients' : 'Add Clients'}
      </DialogTitle>
      <Formik
        enableReinitialize
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={
          initialValues || {
            AuthenticationFlow: IdpClientEnum[2],
            LogoutRedirectUri: '',
            RedirectUri: '',
          }
        }
        onSubmit={(data) => {
          handleSubmit?.({
            appInstanceId,
            ClientName: data.ClientName,
            ClientSecret: data.ClientSecret,
            AuthenticationFlow: data.AuthenticationFlow?.Value,
            RedirectUris: data.RedirectUri.split('\n').map((e) => e.trim()),
            LogoutRedirectUris: data.LogoutRedirectUri.split('\n').map((e) =>
              e.trim(),
            ),
          });
        }}
        validationSchema={validationSchema}
      >
        {({ submitForm, values, setFieldValue, errors }) => (
          <Box>
            <DialogContent>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  <Box component='p' color='text.primary' fontSize={14} mb={2}>
                    Client Name
                    <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                      *
                    </Box>
                  </Box>
                  <TextField
                    fullWidth
                    disabled={!!isEdit}
                    value={values.ClientName ?? ''}
                    error={!!errors.ClientName}
                    helperText={errors.ClientName}
                    sx={{
                      '& input.Mui-disabled': {
                        backgroundColor: grey[50],
                      },
                    }}
                    onChange={(e) =>
                      setFieldValue('ClientName', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box component='p' color='text.primary' fontSize={14} mb={2}>
                    Flow
                    <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                      *
                    </Box>
                  </Box>
                  <Autocomplete
                    disablePortal
                    disabled={!!isEdit}
                    value={values.AuthenticationFlow ?? null}
                    options={IdpClientEnum}
                    getOptionLabel={(row) => row.Description}
                    isOptionEqualToValue={(option, value) =>
                      option.Value === value.Value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={!!errors.AuthenticationFlow}
                        helperText={errors.AuthenticationFlow}
                      />
                    )}
                    sx={{
                      '& input.Mui-disabled': {
                        backgroundColor: grey[50],
                      },
                    }}
                    onChange={(e, value) => {
                      setFieldValue('AuthenticationFlow', value);
                      if (!checkActiveClientSecret(value?.Value)) {
                        setFieldValue('ClientSecret', '');
                      }
                    }}
                  />
                </Grid>
                {!isEdit && (
                  <Grid item xs={12} sm={12}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Client Secret
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      disabled={
                        !!isEdit ||
                        !checkActiveClientSecret(
                          values.AuthenticationFlow?.Value,
                        )
                      }
                      value={values.ClientSecret ?? ''}
                      error={!!errors.ClientSecret}
                      helperText={
                        errors.ClientSecret ||
                        'Important Reminder: Please remember your client secret, as you will not be able to query any information after confirming it.'
                      }
                      sx={{
                        '& input.Mui-disabled': {
                          backgroundColor: grey[50],
                        },
                      }}
                      onChange={(e) =>
                        setFieldValue('ClientSecret', e.target.value)
                      }
                    />
                  </Grid>
                )}
                <Grid item xs={12} sm={12}>
                  <Box component='p' color='text.primary' fontSize={14} mb={2}>
                    Redirect Uri
                    <AppGlossary title='Please break down with multiple value' />
                  </Box>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={values.RedirectUri ?? ''}
                    error={!!errors.RedirectUri}
                    helperText={errors.RedirectUri}
                    onChange={(e) =>
                      setFieldValue('RedirectUri', e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Box component='p' color='text.primary' fontSize={14} mb={2}>
                    Logout Redirect Uri
                    <AppGlossary title='Please break down with multiple value' />
                  </Box>
                  <TextField
                    fullWidth
                    multiline
                    rows={3}
                    value={values.LogoutRedirectUri ?? ''}
                    error={!!errors.LogoutRedirectUri}
                    helperText={errors.LogoutRedirectUri}
                    onChange={(e) =>
                      setFieldValue('LogoutRedirectUri', e.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                loading={loading}
                variant='contained'
                onClick={submitForm}
              >
                Save
              </LoadingButton>
            </DialogActions>
          </Box>
        )}
      </Formik>
    </Dialog>
  );
};

export default React.memo(IdpClientModalForm);

IdpClientModalForm.propTypes = {
  initialValues: PropTypes.object,
  appInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  visible: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  loading: PropTypes.bool,
  isEdit: PropTypes.bool,
};
