import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  ListItemText,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import DockerAppTypes from 'constants/DockerAppTypes';
import { grey } from '@mui/material/colors';
import { useDispatch } from 'react-redux';
import { setFieldDockerImage } from 'redux/slices/dockerImage';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DockerImageVersion from './components/List/DockerImageVersion';
import SimpleBar from 'simplebar-react';

const Detail = ({ item = {} }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const onClickBackButton = () => {
    navigate(ROUTE_STRING.DOCKER_IMAGE.LIST);
    dispatch(setFieldDockerImage({ field: 'viewItem', value: null }));
  };

  useEffect(() => {
    if (!item?.Id) {
      navigate(ROUTE_STRING.DOCKER_IMAGE.LIST);
    }
  }, []);

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
          </Box>
        </Box>
      </AppsHeader>

      <SimpleBar
        style={{ height: 'calc(100vh - 225px)', marginBottom: '20px' }}
      >
        <Box p={4}>
          <Grid container spacing={5}>
            <Grid item xs={12}>
              <Accordion
                defaultExpanded
                sx={{
                  boxShadow: 'none',
                  backgroundColor: grey[50],
                  pl: 5,
                  pr: 5,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h4'> Image Info </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                      <ListItemText
                        primary='Image Name'
                        secondary={item.ImageName ?? '-'}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <ListItemText
                        primary='Deployment Type'
                        secondary={
                          DockerAppTypes.find(
                            (e) => e.Value === item.DeploymentType,
                          )?.Description || '-'
                        }
                      />
                    </Grid>
                    {!DockerAppTypes.find(
                      (e) => e.Value === item.DeploymentType,
                    )?.IsCron && (
                      <Grid item xs={12} sm={3}>
                        <ListItemText
                          primary='Target Port'
                          secondary={item.TargetPort ?? '-'}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12}>
                      <ListItemText
                        primary='Required Environment Variables'
                        secondary={item.RequiredEnviromentVariables?.split(
                          ',',
                        ).map((e) => (
                          <Chip key={e} label={e} size='small' sx={{ mr: 2 }} />
                        ))}
                        secondaryTypographyProps={{
                          sx: {
                            mt: 1,
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <Accordion
                defaultExpanded
                sx={{
                  boxShadow: 'none',
                  backgroundColor: grey[50],
                  pl: 5,
                  pr: 5,
                }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant='h4'> Image Source </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={12}>
                      <ListItemText
                        primary='Repository Url'
                        secondary={item.RepositoryUrl ?? ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ListItemText
                        primary='Branch Name'
                        secondary={item.BranchName ?? '-'}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ListItemText
                        primary='Dockerfile Path'
                        secondary={item.DockerfilePath ?? '-'}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
            <Grid item xs={12}>
              <DockerImageVersion rows={item.DockerImages ?? []} />
            </Grid>
          </Grid>
        </Box>
      </SimpleBar>
    </>
  );
};

export default React.memo(Detail);
