import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import useModal from '@tenant/utility/hooks/useModal';
import { Formik } from 'formik';
import * as yup from 'yup';
import { grey } from '@mui/material/colors';
import { deepClone } from '@tenant/utility/helper/Utils';
import useUpdateEnvironments from 'redux/slices/containerApp/hooks/useUpdateEnvironments';
import transformEnvToObject from 'redux/slices/containerApp/helpers/transformEnvToObject';
import { LoadingButton } from '@mui/lab';
import AppSearchInput from '@tenant/core/AppSearchBar/AppSearchInput';
import DeleteIcon from '@mui/icons-material/Delete';
import AppIdpClientSelect from '@tenant/core/App/Select/AppIdpClientSelect';
import AppDelayedRender from '@tenant/core/App/Render/AppDelayedRender';

const validationSchema = yup.object({
  key: yup.string().required().label('Key'),
  value: yup.string().required().label('Value'),
});

const convertEnvironmentToArray = (environmentVariables) => {
  if (!environmentVariables) {
    return [];
  }

  const result = [];
  let index = 0;

  for (let i in environmentVariables) {
    result.push({ key: i, value: environmentVariables[i], index: index });
    index += 1;
  }
  return result;
};

const EnvironmentVariablesDescription = ({
  environmentVariables,
  appInstanceId,
  containerAppId,
  refetchDetail = () => {},
}) => {
  const { visible, onClose, onShow } = useModal();
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState('');
  const [data, setData] = useState([]);
  const updateEnvironmentVariablesMutate = useUpdateEnvironments();
  const isChanged = useMemo(
    () =>
      JSON.stringify(
        data.reduce((acc, item, index) => {
          const { key, value } = item;
          acc[key] = value;
          return acc;
        }, {}),
      ) !== JSON.stringify(environmentVariables),
    [data, environmentVariables],
  );
  const rows = useMemo(
    () =>
      data?.filter(
        (e) => e.key?.toLowerCase().indexOf(search.toLowerCase()) >= 0,
      ) ?? [],
    [data, search],
  );

  useEffect(() => {
    setData(convertEnvironmentToArray(environmentVariables));
  }, [environmentVariables]);

  const handleShow = (item) => {
    setSelected(deepClone(item));
    onShow();
  };

  const handleDelete = (index) => {
    setData((s) => s.filter((s) => s.index !== index));
  };

  const handleClose = () => {
    onClose();
    setSelected();
  };

  const columns = [
    {
      field: 'key',
      headerName: 'Key',
      width: 500,
      sortable: true,
      flex: 1,
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 500,
      sortable: true,
    },
    {
      field: 'Action',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <>
          <IconButton
            size='small'
            color='primary'
            title='Edit'
            onClick={() => handleShow(params.row)}
          >
            <EditIcon fontSize='small' />
          </IconButton>
          <IconButton
            size='small'
            color='error'
            title='Delete'
            onClick={() => handleDelete(params.row.index)}
          >
            <DeleteIcon fontSize='small' />
          </IconButton>
        </>
      ),
    },
  ];

  const addNewRow = () => {
    setSelected({ key: '', value: '', title: 'Add Environment Variables' });
    onShow();
  };

  const addClientId = () => {
    setSelected({
      key: 'client_id',
      value: '',
      title: 'Add Environment Variables with Client Id',
      isClientId: true,
    });
    onShow();
  };

  const onSubmit = () => {
    updateEnvironmentVariablesMutate.mutate(
      {
        appInstanceId,
        containerAppId,
        ...transformEnvToObject(data),
      },
      {
        onSuccess: () => {
          refetchDetail();
        },
      },
    );
  };

  return (
    <AppDelayedRender>
      <Box sx={{ height: 550 }}>
        {visible && (
          <Dialog open={visible} onClose={handleClose} maxWidth='sm' fullWidth>
            <DialogTitle fontSize={16}>
              {selected?.title || 'Edit Environment Variables'}
            </DialogTitle>

            <Formik
              validateOnChange
              validateOnBlur
              initialValues={{
                key: selected?.key,
                value: selected?.value,
                isClientId: selected?.isClientId,
              }}
              validationSchema={validationSchema}
              onSubmit={(data, { setSubmitting }) => {
                setData((s) =>
                  s.some((s) => s.key === data.key)
                    ? s.map((e) => (e.key === data.key ? data : e))
                    : [...s, data],
                );
                handleClose();
              }}
              enableReinitialize
            >
              {({ values, setFieldValue, submitForm, errors }) => (
                <>
                  <DialogContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={12}>
                        <Box
                          component='p'
                          color='text.primary'
                          fontSize={14}
                          mb={2}
                        >
                          Key
                          <Box
                            component='span'
                            ml={1}
                            sx={{ color: 'error.main' }}
                          >
                            *
                          </Box>
                        </Box>
                        <TextField
                          fullWidth
                          value={values.key ?? ''}
                          error={!!errors.key}
                          helperText={errors.key}
                          onChange={(e) => setFieldValue('key', e.target.value)}
                          sx={{ '& .Mui-disabled': { background: grey[50] } }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <Box
                          component='p'
                          color='text.primary'
                          fontSize={14}
                          mb={2}
                        >
                          Value
                          <Box
                            component='span'
                            ml={1}
                            sx={{ color: 'error.main' }}
                          >
                            *
                          </Box>
                        </Box>
                        {!selected?.isClientId ? (
                          <TextField
                            fullWidth
                            value={values.value ?? ''}
                            error={!!errors.value}
                            helperText={errors.value}
                            onChange={(e) =>
                              setFieldValue('value', e.target.value)
                            }
                            sx={{ '& .Mui-disabled': { background: grey[50] } }}
                          />
                        ) : (
                          <AppIdpClientSelect
                            appInstanceId={appInstanceId}
                            value={values.idpClient ?? null}
                            error={!!errors.value}
                            helperText={errors.value}
                            onChange={(e, newValue) => {
                              setFieldValue('idpClient', newValue);
                              setFieldValue('value', newValue?.ClientName);
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={submitForm} variant='contained'>
                      Save
                    </Button>
                  </DialogActions>
                </>
              )}
            </Formik>
          </Dialog>
        )}

        {isChanged && (
          <Box mb={3}>
            <Alert
              severity='info'
              action={
                <>
                  <LoadingButton
                    disabled={updateEnvironmentVariablesMutate?.isPending}
                    size='small'
                    color='error'
                    onClick={() =>
                      setData(convertEnvironmentToArray(environmentVariables))
                    }
                  >
                    Reset
                  </LoadingButton>
                  <LoadingButton
                    loading={updateEnvironmentVariablesMutate?.isPending}
                    size='small'
                    onClick={onSubmit}
                  >
                    Save Changes
                  </LoadingButton>
                </>
              }
            >
              You have change environment variables. Do you want update new
              changes
            </Alert>
          </Box>
        )}

        <Box display={'flex'} justifyContent={'space-between'} mb={3}>
          <AppSearchInput
            isClearable
            searchValue={search ?? ''}
            onClearSearch={() => setSearch('')}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Box justifyContent={'end'} display={'flex'}>
            <Button onClick={addClientId}>Add Client ID</Button>
            <Button onClick={addNewRow}>Add Row</Button>
          </Box>
        </Box>

        <DataGrid
          hideFooter
          disableColumnSelector
          disableColumnMenu
          rows={rows ?? []}
          columns={columns}
          getRowId={(row) => row.key}
          sx={{
            '& .MuiDataGrid-cellContent': {
              whiteSpace: 'normal',
            },
          }}
        />
      </Box>
    </AppDelayedRender>
  );
};

export default React.memo(EnvironmentVariablesDescription);

EnvironmentVariablesDescription.propTypes = {
  environmentVariables: PropTypes.object,
  appInstanceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerAppId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  refetchDetail: PropTypes.func,
};
