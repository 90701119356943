/**
 *
 * @param {*} dockerImages: list response from API
 * @returns new list of docker images with the specified field
 */
export default function (dockerImages = []) {
  return dockerImages.map((dockerImage) => {
    const {
      Id,
      DeploymentType,
      RequiredEnviromentVariables,
      RepositoryUrl,
      BranchName,
      DockerfilePath,
      TargetPort,
    } = dockerImage?.DockerImages?.[0] ?? {};
    return {
      ...dockerImage,
      DeploymentType,
      Id,
      RequiredEnviromentVariables,
      RepositoryUrl,
      BranchName,
      DockerfilePath,
      TargetPort,
    };
  });
}
