export const authRole = {
  Admin: ['admin'],
  User: ['user', 'admin'],
};

export const RoutePermittedRole = {
  Admin: 'admin',
  User: 'user',
};

export const defaultUser = {
  displayName: 'cuongn',
  email: 'cuongn@joblogic.com',
  token: 'access-token',
  role: 'user',
  photoURL: '/assets/images/avatar/A11.jpg',
};
export const fileStackKey = 'Ach6MsgoQHGK6tCaq5uJgz';
// export const initialUrl = '/dashboards/analytics'; // this url will open after login
export const initialUrl = '/list-app'; // this url will open after login
