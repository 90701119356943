import React from 'react';
import PropTypes from 'prop-types';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import {
  ClassicEditor,
  Autoformat,
  Bold,
  Italic,
  Underline,
  BlockQuote,
  Base64UploadAdapter,
  CKFinder,
  CKFinderUploadAdapter,
  CloudServices,
  CKBox,
  Essentials,
  Heading,
  Image,
  ImageCaption,
  ImageResize,
  ImageStyle,
  ImageToolbar,
  ImageUpload,
  PictureEditing,
  Indent,
  IndentBlock,
  Link,
  List,
  MediaEmbed,
  Mention,
  Paragraph,
  PasteFromOffice,
  Table,
  TableColumnResize,
  TableToolbar,
  TextTransformation,
  HtmlEmbed,
  Strikethrough,
  Alignment,
  HorizontalLine,
} from 'ckeditor5';
import { Box } from '@mui/material';

const AppCKEditor = ({ data, handleChange, error, helperText }) => {
  return (
    <Box
      sx={{
        '& .ck-editor': {
          border: error ? '1px solid red !important' : '0',
        },
        '& .ck-content': {
          padding: '0 2rem !important',
        },
      }}
    >
      <CKEditor
        editor={ClassicEditor}
        config={{
          plugins: [
            Autoformat,
            BlockQuote,
            HtmlEmbed,
            Bold,
            CKFinder,
            CKFinderUploadAdapter,
            CloudServices,
            Essentials,
            Heading,
            Image,
            ImageCaption,
            ImageResize,
            ImageStyle,
            ImageToolbar,
            ImageUpload,
            Base64UploadAdapter,
            Indent,
            IndentBlock,
            Italic,
            Link,
            List,
            MediaEmbed,
            Mention,
            Paragraph,
            PasteFromOffice,
            PictureEditing,
            Table,
            TableColumnResize,
            TableToolbar,
            TextTransformation,
            Underline,
            Strikethrough,
            Alignment,
            HorizontalLine,
          ],
          toolbar: [
            'undo',
            'redo',
            '|',
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            '|',
            'link',
            'uploadImage',
            'ckbox',
            'insertTable',
            'blockQuote',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            'htmlEmbed',
            'alignment',
            'horizontalLine',
          ],
          heading: {
            options: [
              {
                model: 'paragraph',
                title: 'Paragraph',
                class: 'ck-heading_paragraph',
              },
              {
                model: 'heading1',
                view: 'h1',
                title: 'Heading 1',
                class: 'ck-heading_heading1',
              },
              {
                model: 'heading2',
                view: 'h2',
                title: 'Heading 2',
                class: 'ck-heading_heading2',
              },
              {
                model: 'heading3',
                view: 'h3',
                title: 'Heading 3',
                class: 'ck-heading_heading3',
              },
              {
                model: 'heading4',
                view: 'h4',
                title: 'Heading 4',
                class: 'ck-heading_heading4',
              },
            ],
          },
          image: {
            resizeOptions: [
              {
                name: 'resizeImage:original',
                label: 'Default image width',
                value: null,
              },
              {
                name: 'resizeImage:50',
                label: '50% page width',
                value: '50',
              },
              {
                name: 'resizeImage:75',
                label: '75% page width',
                value: '75',
              },
            ],
            toolbar: [
              'imageTextAlternative',
              'toggleImageCaption',
              '|',
              'imageStyle:inline',
              'imageStyle:wrapText',
              'imageStyle:breakText',
              '|',
              'resizeImage',
            ],
          },
          link: {
            addTargetToExternalLinks: true,
            defaultProtocol: 'https://',
          },
          table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
          },
        }}
        data={data}
        onChange={handleChange}
      />

      {!!error && (
        <Box
          component={'p'}
          className='MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1y1no8y-MuiFormHelperText-root'
        >
          {helperText}
        </Box>
      )}
    </Box>
  );
};

export default React.memo(AppCKEditor);

AppCKEditor.propTypes = {};
