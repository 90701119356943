import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Cron from 'react-cron-generator';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from '@mui/material';

const CronModal = ({
  value,
  visible,
  handleClose = () => {},
  handleSave = () => {},
}) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    setState(value);
  }, [value]);

  return (
    <Dialog open={visible} onClose={handleClose} size='md' fullWidth>
      <DialogTitle fontSize={16}>Set Cron</DialogTitle>
      <DialogContent>
        <Cron
          onChange={(value) => setState(value)}
          value={state}
          showResultText={true}
          showResultCron={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => handleSave(state)} variant='contained'>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(CronModal);

CronModal.propTypes = {};
