import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Tooltip, Typography } from '@mui/material';
import { formatDateFromISO } from '@tenant/utility/helper/DateHelper';
import { inputFormatDateTime } from '@tenant/utility/constants/default';

const DockerImageVersion = ({ loading, rows = [] }) => {
  const columns = [
    {
      field: 'ImageFullName',
      headerName: 'Image Name',
      width: 450,
      sortable: true,
      flex: 1,
    },
    {
      field: 'ImageId',
      headerName: 'Image ID',
      width: 450,
      sortable: true,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Typography
            variant='body2'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {params.value}
          </Typography>
        </Tooltip>
      ),
    },
    {
      field: 'Tag',
      headerName: 'Tag',
      width: 75,
      sortable: true,
    },
    {
      field: 'CreatedAt',
      headerName: 'Created On',
      width: 150,
      sortable: false,
      renderCell: (params) =>
        params.value
          ? formatDateFromISO(params.value, inputFormatDateTime)
          : null,
    },
    {
      field: 'UpdatedAt',
      headerName: 'Updated On',
      width: 150,
      sortable: false,
      renderCell: (params) =>
        params.value
          ? formatDateFromISO(params.value, inputFormatDateTime)
          : null,
    },
  ];

  return (
    <Box sx={{ height: 400 }}>
      <Typography variant='h4' mt={5} mb={5}>
        Image Versions{' '}
      </Typography>
      <DataGrid
        hideFooter
        disableColumnSelector
        disableColumnMenu
        rows={rows}
        columns={columns}
        getRowId={(row) => row.Id}
        loading={loading}
      />
    </Box>
  );
};

export default React.memo(DockerImageVersion);

DockerImageVersion.propTypes = {};
