import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

const AppDelayedRender = ({ children, time = 100 }) => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, time);

    return () => clearTimeout(timer);
  }, []);

  return <div>{isVisible && children}</div>;
};

export default React.memo(AppDelayedRender);

AppDelayedRender.propTypes = {
  children: PropTypes.node,
  time: PropTypes.number,
};
