import { combineReducers } from 'redux';
import commonReducer from './common';
import settingReducer from './setting';
import authorizationReducer from './authorization';
import selectReducer from './select';
import dockerImageReducer from './dockerImage';
import appPackageReducer from './appPackage';
import appInstanceReducer from './appInstance';
import containerAppReducer from './containerApp';

export const rootReducer = combineReducers({
  common: commonReducer,
  settings: settingReducer,
  authorization: authorizationReducer,
  select: selectReducer,
  dockerImage: dockerImageReducer,
  appPackage: appPackageReducer,
  appInstance: appInstanceReducer,
  containerApp: containerAppReducer,
});
