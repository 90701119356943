import React from 'react';
import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import { FieldArray } from 'formik';

import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import DeleteSharpIcon from '@mui/icons-material/DeleteOutlineOutlined';
import PageSelector from './elements/PageSelector';
import Typography from '@mui/material/Typography';
import ArrowRightOutlined from '@mui/icons-material/ArrowRightOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Close from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Popover from '@mui/material/Popover';
import { ReactComponent as NoItem } from '../../../../assets/icon/no-item.svg';
import IconButton from '@mui/material/IconButton';
import AlertConfirmDialog from '../../../../components/AlertConfirmDialog';
import { HelpCenterOutlined, Preview } from '@mui/icons-material';
import { listPages, listDirection } from 'constants/FeaturesContants';
import Add from '@mui/icons-material/Add';

const FeatureStepWebMarketPlace = ({ values, setFieldValue }) => {
  const isValidFeatureItem = (feature) => {
    return feature.source && feature.featureName;
  };

  const [currentListPagesAvailable, setCurrentListPagesAvailable] =
    React.useState(cloneDeep(listPages));

  React.useEffect(() => {
    const selectedPages = values.PageSlots.map((item) => item.matchRegex);
    setCurrentListPagesAvailable(
      listPages.filter((item) => !selectedPages.includes(item.matchRegex)),
    );
  }, [values.PageSlots]);

  const getListSlotsByPage = (pageKey, pageSelectedSlot) => {
    const pageSelectedSlotKey =
      pageSelectedSlot?.map((item) => item.type) ?? [];
    const result =
      listPages.find((item) => pageKey === item.matchRegex)?.slots ?? [];

    return result
      .filter((item) => !pageSelectedSlotKey.includes(item))
      .map((item) => ({
        type: item,
        label: startCase(item),
      }));
  };

  const getPageInfo = (pageKey, keyField) => {
    const result = listPages.find((item) => item.matchRegex === pageKey);

    if (result && keyField) return get(result, keyField);
    return result;
  };

  const getCurrentPageSlotDescriptionText = (slots) => {
    const defaultText = 'No slots selected';

    if (!slots?.length) return defaultText;

    const result = [];

    slots.map((slot) => {
      const featuresLength = slot.features?.filter?.((feature) =>
        isValidFeatureItem(feature),
      )?.length;

      if (featuresLength > 0) result.push(`${featuresLength} ${slot.label}`);
    });

    return result.join(', ') || defaultText;
  };

  const featureItemObj = {
    source: '',
    featureName: '',
    location: listDirection[0].key,
  };
  const pageItemObj = { matchRegex: '', slots: [] };

  // Modal
  const [open, setOpen] = React.useState(false);
  const [currentPageIndex, setCurrentPageIndex] = React.useState(null);
  const handleClickOpenModal = (index) => {
    setOpen(true);
    setCurrentPageIndex(index);

    setFieldValue(`PageSlotsTemp`, values.PageSlots);
  };
  const handleCloseModal = (event, reason) => {
    if (reason === 'backdropClick') {
      return;
    }

    setOpen(false);
    setCurrentPageIndex(null);

    setFieldValue(`PageSlotsTemp`, null);
  };

  // Popper
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClickOpenPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosePopover = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [openConfirmDelete, setOpenConfirmDelete] = React.useState(false);
  const [currentAction, setCurrentAction] = React.useState(null);

  const handleClickOpenConfirm = (action, ...args) => {
    setOpenConfirmDelete(true);
    setCurrentAction({ action, args });
  };

  const handleClose = () => {
    setOpenConfirmDelete(false);
    setCurrentAction(null);
  };

  const handleConfirm = () => {
    if (currentAction) {
      setCurrentAction(null);
      const { action, args } = currentAction;
      if (typeof action === 'function') action(...args);
    }
    setOpenConfirmDelete(false);
  };

  return (
    <>
      <AlertConfirmDialog
        open={openConfirmDelete}
        title='Confirm Action'
        message='Are you sure you want to proceed?'
        onConfirm={handleConfirm}
        onCancel={handleClose}
      />

      <Grid item xs={12} sm={12} md={12}>
        <Tooltip
          placement='right'
          title='This field contains an embedded link to the BAU for quick navigation.'
        >
          <Box
            sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              marginBottom: 1,
              width: 'fit-content',
            }}
          >
            <Typography variant='h4'>Embed Source URL</Typography>
            <HelpCenterOutlined sx={{ color: 'rgb(17, 24, 39)' }} />
          </Box>
        </Tooltip>
        <TextField
          placeholder='Enter source url'
          value={values.EmbeddedUrl ?? ''}
          onChange={async (e) => {
            setFieldValue('EmbeddedUrl', e.target.value);
          }}
          onBlur={() => {
            setFieldValue('EmbeddedUrl', values.EmbeddedUrl?.trim());
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            height: '1px',
            width: 'full',
            backgroundColor: '#8080804a',
            display: 'block',
          }}
        ></Box>
      </Grid>

      <FeatureConfigurationHeader
        values={values}
        currentListPagesAvailable={currentListPagesAvailable}
        pageItemObj={pageItemObj}
      />

      <Grid item xs={12} sm={12} md={12} sx={{ mt: 4 }}>
        <FieldArray name='PageSlots'>
          {({ remove: removePage }) => (
            <>
              {values.PageSlots.length > 0 ? (
                values.PageSlots.map((itempage, index) => (
                  <Box
                    key={index}
                    mb={4}
                    sx={{
                      '&:hover': {
                        boxShadow: 1,
                      },
                      cursor: 'pointer',
                      border: 1,
                      borderColor: '#8080804a',
                      borderRadius: 2,
                      p: 4,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 4,
                    }}
                    onClick={() => {
                      handleClickOpenModal(index);
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                      <Typography>
                        {getPageInfo(
                          get(values, `PageSlots.${index}.matchRegex`),
                          'name',
                        )}
                      </Typography>
                      <ArrowRightOutlined />
                      <Typography variant='caption'>
                        {getCurrentPageSlotDescriptionText(
                          get(values, `PageSlots.${index}.slots`),
                        )}
                      </Typography>
                    </Box>

                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleClickOpenConfirm(removePage, index);
                      }}
                    >
                      <DeleteSharpIcon size='small' sx={{ color: 'grey' }} />
                    </IconButton>
                  </Box>
                ))
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '200px',
                  }}
                >
                  <NoItem />

                  <Typography sx={{ marginTop: 2 }} variant='caption'>
                    No item displayed
                  </Typography>

                  <Box sx={{ marginTop: 2 }}>
                    <FieldArray name='PageSlots'>
                      {({ push: pushPage }) => (
                        <PageSelector
                          options={currentListPagesAvailable}
                          onChange={(value) => {
                            pushPage({
                              ...pageItemObj,
                              matchRegex: value.matchRegex,
                            });
                          }}
                        />
                      )}
                    </FieldArray>
                  </Box>
                </Box>
              )}
            </>
          )}
        </FieldArray>
      </Grid>

      <Dialog
        open={open}
        onClose={handleCloseModal}
        maxWidth={false}
        onBackdropClick={(e) => e.stopPropagation()} // Prevent closing on backdrop click
      >
        <DialogContent sx={{ minWidth: 900 }}>
          <Typography variant={'h3'}>
            {' '}
            {getPageInfo(
              get(values, `PageSlotsTemp.${currentPageIndex}.matchRegex`),
              'name',
            )}
          </Typography>

          <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', gap: 2, mt: 4 }}>
            <img src={getPageInfo(
              get(values, `PageSlotsTemp.${currentPageIndex}.matchRegex`),
              'image',
            )} style={{ maxHeight: '400px' }} />

            <Typography variant='p' sx={{ textAlign: 'center' }}>
              Select the slot type to create appropriate new elements (tabs, buttons, or sections, etc). These elements will be displayed according to the guideline above.
            </Typography>
          </Box>

          <FieldArray name={`PageSlotsTemp.${currentPageIndex}.slots`}>
            {({ remove: removeSlot, push: pushSlot }) => (
              <>
                {get(values, `PageSlotsTemp.${currentPageIndex}.slots.length`) >
                  0 ? (
                  get(
                    values,
                    `PageSlotsTemp.${currentPageIndex}.slots`,
                    [],
                  ).map((itemSlot, indexSlot) => (
                    <Box
                      key={indexSlot}
                      sx={{
                        mt: 2,
                        border: 1,
                        borderColor: '#8080804a',
                        borderRadius: 2,
                        p: 4,
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: 4,
                          mb: 2,
                        }}
                      >
                        <Typography>
                          {get(
                            values,
                            `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].label`,
                          )}
                        </Typography>

                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClickOpenConfirm(removeSlot, indexSlot);
                          }}
                        >
                          <DeleteSharpIcon
                            size='small'
                            sx={{ color: 'grey' }}
                          />
                        </IconButton>
                      </Box>

                      <FieldArray
                        name={`PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features`}
                      >
                        {({ remove: removeFeature, push: pushFeature }) => (
                          <>
                            {get(
                              values,
                              `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features.length`,
                            ) > 0 ? (
                              get(
                                values,
                                `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features`,
                                [],
                              ).map((itemFeature, indexFeature) => (
                                <Box
                                  key={indexFeature}
                                  style={{ marginBottom: '12px' }}
                                >
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      gap: 2,
                                      alignItems: 'center',
                                    }}
                                  >
                                    <FormControl fullWidth>
                                      <Select
                                        labelId='demo-simple-select-label'
                                        size='small'
                                        value={
                                          get(
                                            values,
                                            `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features[${indexFeature}].location`,
                                          ) ?? ''
                                        }
                                        onChange={async (e) => {
                                          setFieldValue(
                                            `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features[${indexFeature}].location`,
                                            e.target.value,
                                          );
                                        }}
                                      >
                                        {listDirection.map((itemLocation) => {
                                          return (
                                            <MenuItem
                                              key={itemLocation.key}
                                              value={itemLocation.key}
                                            >
                                              {itemLocation.label}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                      <TextField
                                        size='small'
                                        placeholder='Type feature name'
                                        value={
                                          get(
                                            values,
                                            `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features[${indexFeature}].featureName`,
                                          ) ?? ''
                                        }
                                        onChange={async (e) => {
                                          setFieldValue(
                                            `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features[${indexFeature}].featureName`,
                                            e.target.value,
                                          );
                                        }}
                                        fullWidth
                                      />
                                    </FormControl>
                                    <FormControl fullWidth>
                                      <TextField
                                        size='small'
                                        placeholder='Paste the source here'
                                        value={
                                          get(
                                            values,
                                            `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features[${indexFeature}].source`,
                                          ) ?? ''
                                        }
                                        onChange={async (e) => {
                                          setFieldValue(
                                            `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features[${indexFeature}].source`,
                                            e.target.value,
                                          );
                                        }}
                                        fullWidth
                                      />
                                    </FormControl>
                                    <IconButton
                                      onClick={() => {
                                        if (isValidFeatureItem(get(
                                          values,
                                          `PageSlotsTemp.${currentPageIndex}.slots[${indexSlot}].features[${indexFeature}]`,
                                        ))) {
                                          handleClickOpenConfirm(
                                            removeFeature,
                                            indexFeature,
                                          );
                                        }
                                        else {
                                          removeFeature(indexFeature)
                                        }

                                      }}
                                    >
                                      <Close />
                                    </IconButton>
                                  </Box>
                                </Box>
                              ))
                            ) : (
                              <Box
                                sx={{
                                  flexDirection: 'column',
                                  minHeight: 75,
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography
                                  sx={{ marginTop: 2 }}
                                  variant='caption'
                                >
                                  No item displayed
                                </Typography>
                              </Box>
                            )}

                            <Button startIcon={<Add />} onClick={() =>
                              pushFeature(cloneDeep(featureItemObj))
                            }>Add feature</Button>
                          </>
                        )}
                      </FieldArray>
                    </Box>
                  ))
                ) : (
                  <Box
                    sx={{
                      flexDirection: 'column',
                      minHeight: 100,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <NoItem />
                    <Typography sx={{ marginTop: 2 }} variant='caption'>
                      No item displayed
                    </Typography>
                  </Box>
                )}

                {getListSlotsByPage(
                  get(values, `PageSlotsTemp.${currentPageIndex}.matchRegex`),
                  get(values, `PageSlotsTemp.${currentPageIndex}.slots`),
                )?.length > 0 && (
                    <Button startIcon={<Add />} onClick={handleClickOpenPopover} sx={{ marginTop: 2 }}>Add slot</Button>
                  )}

                <Popover
                  id={id}
                  open={openPopover}
                  anchorEl={anchorEl}
                  onClose={handleClosePopover}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <Box
                    sx={{ bgcolor: 'background.paper', borderRadius: 16, p: 2 }}
                  >
                    <Autocomplete
                      onChange={(event, value) => {
                        pushSlot(value);
                        handleClosePopover();
                      }}
                      sx={{ width: 300 }}
                      options={getListSlotsByPage(
                        get(
                          values,
                          `PageSlotsTemp.${currentPageIndex}.matchRegex`,
                        ),
                        get(values, `PageSlotsTemp.${currentPageIndex}.slots`),
                      )}
                      getOptionLabel={(option) => option.label}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          placeholder='Search slots'
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option?.type === value?.type
                      }
                    ></Autocomplete>
                  </Box>
                </Popover>
              </>
            )}
          </FieldArray>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between' }}>
            <FieldArray name='PageSlots'>
              {({ remove: removePage }) => (
                <>
                  <Button
                    onClick={() => {
                      const mainFunction = (indexRemove) => {
                        removePage(indexRemove);
                        handleCloseModal();
                      };
                      handleClickOpenConfirm(mainFunction, currentPageIndex);
                    }}
                    size='small'
                    color='error'
                  >
                    Delete
                  </Button>
                </>
              )}
            </FieldArray>

            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={() => {
                  handleCloseModal();
                }}
                size='small'
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setFieldValue('PageSlots', values.PageSlotsTemp);
                  handleCloseModal();
                }}
                variant='contained'
                size='small'
              >
                Done
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

function FeatureConfigurationHeader({
  values,
  pageItemObj,
  currentListPagesAvailable,
}) {
  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Box
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant='h4' gutterBottom>
              Feature Configuration for Web Market Place
            </Typography>
            <Typography variant='caption' gutterBottom>
              Customize and manage additional features for this specific page.
            </Typography>
          </Box>

          <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
            {values.PageSlots.length > 0 && (
              <FieldArray name='PageSlots'>
                {({ push: pushPage }) => (
                  <PageSelector
                    options={currentListPagesAvailable}
                    onChange={(value) => {
                      pushPage({
                        ...pageItemObj,
                        matchRegex: value.matchRegex,
                      });
                    }}
                  />
                )}
              </FieldArray>
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
}

export default React.memo(FeatureStepWebMarketPlace);
