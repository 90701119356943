import React from 'react';
import { Box, LinearProgress, linearProgressClasses } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import styled from '@emotion/styled';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 6,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#FFC205' : '#308fe8',
    },
  }));

const RowStar = ({star, value}) => {
  return (
    <Box className='row-star'>
      <Box className='star'>
        <span className='number'>{star}</span>
        <StarIcon fontSize={'16'} />
      </Box>
      <Box className='progress'>
        <BorderLinearProgress variant='determinate' value={value} />
      </Box>
    </Box>
  );
};

export default RowStar;
