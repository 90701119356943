import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Chip,
  Divider,
  Grid,
  LinearProgress,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Tab,
  Tooltip,
  Typography,
  linearProgressClasses,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import StarIcon from '@mui/icons-material/Star';
import { DATE_TIME_FORMAT } from 'constants/Common';
import moment from 'moment';
import styled from '@emotion/styled';

const TAB_OVERVIEW = '1';
const TAB_RATING = '2';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 6,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

const AppPreviewMarketPlace = ({
  iconBlobReference,
  appUrl,
  shortDescription,
  name,
  keyLabels,
  longDescription,
  keyBenefits,
  mobileAppVersion,
  appVersion,
  lastUpdated,
  totalRating,
  categories = [],
  dependencies,
  price,
  priceType,
  isRating = true,
}) => {
  const [value, setValue] = React.useState(TAB_OVERVIEW);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid container>
      <Grid item xs={12} sm={12}>
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            '& .img-col': { minWidth: 125 },
            '& .content-col': {
              width: 'calc(100% - 125px)',
            },
          }}
        >
          <Box className='img-col'>
            <img
              src={iconBlobReference || appUrl}
              alt='logo'
              style={{
                height: 100,
                width: 100,
                borderRadius: '1rem',
                p: 1,
              }}
            />
          </Box>
          <Box className='content-col'>
            <Typography variant='h4' mb={2}>
              {name}
            </Typography>
            <Typography variant='caption'>By Joblogic Marketplace</Typography>
            <Tooltip title={shortDescription}>
              <Typography
                mb={1}
                variant='caption'
                component='p'
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {shortDescription}
              </Typography>
            </Tooltip>
            {!!keyLabels && (
              <Chip
                size='small'
                label={keyLabels}
                color='success'
                variant='outlined'
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={12}>
        <Box sx={{ width: '100%', typography: 'body1', mt: 2 }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label='lab API tabs example'
              >
                <Tab label='Overview' value='1' />
                {isRating && <Tab label='Ratings & Reviews' value='2' />}
              </TabList>
            </Box>
            <TabPanel value={TAB_OVERVIEW}>
              <Grid container>
                <Grid item xs={12} sm={9} sx={{ p: 4, pl: 0 }}>
                  <Typography
                    sx={{ lineHeight: '24px' }}
                    variant='body2'
                    component='div'
                    dangerouslySetInnerHTML={{
                      __html: longDescription,
                    }}
                  />
                  <Typography variant='h2' sx={{ my: 4 }}>
                    Key Benefits:
                  </Typography>
                  <Typography
                    sx={{ lineHeight: '24px' }}
                    variant='body2'
                    component='div'
                    dangerouslySetInnerHTML={{
                      __html: keyBenefits,
                    }}
                  />
                </Grid>
                <Divider orientation='vertical' flexItem sx={{ mr: '-1px' }} />
                <Grid item xs={12} sm={3}>
                  {appVersion && (
                    <ListItem>
                      <ListItemText primary='Version' secondary={appVersion} />
                    </ListItem>
                  )}

                  {mobileAppVersion && (
                    <ListItem>
                      <ListItemText
                        primary='Mobile App Version'
                        secondary={mobileAppVersion}
                      />
                    </ListItem>
                  )}

                  <ListItem>
                    <ListItemText
                      primary='Last updated'
                      secondary={
                        lastUpdated
                          ? moment(lastUpdated).format(DATE_TIME_FORMAT)
                          : null
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Categories'
                      secondary={categories
                        ?.map((item) => item.Name)
                        .join(' | ')}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary='Dependencies'
                      secondary={dependencies}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary='Price Type' secondary={priceType} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary='Price' secondary={price} />
                  </ListItem>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel
              value={TAB_RATING}
              sx={{
                '& svg': { fill: '#faaf00', ml: 1, fontSize: 18 },
                '& .progress': { width: 300, ml: 5 },
                '& .number': {
                  width: 10,
                },
                '& .star': {
                  display: 'flex',
                  alignItems: 'center',
                  width: '30px',
                },
                '& .row-star': {
                  display: 'flex',
                  alignItems: 'center',
                  margin: '5px 0',
                },
              }}
            >
              <Grid container>
                <Grid item xs={2}>
                  <Typography variant='overline'>
                    {totalRating} {totalRating < 2 ? 'rating' : 'ratings'}{' '}
                  </Typography>
                </Grid>
                <Grid item xs={10}></Grid>
              </Grid>
              <Box className='row-star'>
                <Box className='star'>
                  <span className='number'>5</span>
                  <StarIcon fontSize={'16'} />
                </Box>
                <Box className='progress'>
                  <BorderLinearProgress variant='determinate' value={96} />
                </Box>
              </Box>
              <Box className='row-star'>
                <Box className='star'>
                  <span className='number'>4</span> <StarIcon fontSize={'16'} />
                </Box>
                <Box className='progress'>
                  <BorderLinearProgress variant='determinate' value={79} />
                </Box>
              </Box>
              <Box className='row-star'>
                <Box className='star'>
                  <span className='number'>3</span> <StarIcon fontSize={'16'} />
                </Box>
                <Box className='progress'>
                  <BorderLinearProgress variant='determinate' value={0} />
                </Box>
              </Box>
              <Box className='row-star'>
                <Box className='star'>
                  <span className='number'>2</span>
                  <StarIcon fontSize={'16'} />
                </Box>
                <Box className='progress'>
                  <BorderLinearProgress variant='determinate' value={0} />
                </Box>
              </Box>
              <Box className='row-star'>
                <Box className='star'>
                  <span className='number'>1</span>
                  <StarIcon fontSize={'16'} />
                </Box>
                <Box className='progress'>
                  <BorderLinearProgress variant='determinate' value={0} />
                </Box>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Grid>
    </Grid>
  );
};

export default React.memo(AppPreviewMarketPlace);

AppPreviewMarketPlace.propTypes = {};
