import { ROUTE_STRING } from 'constants/RouteString';
import Detail from './Detail';
import { RoutePermittedRole } from 'constants/AppConst';

export const containerAppRoutes = [
  {
    permittedRole: RoutePermittedRole.User,
    path: [ROUTE_STRING.CONTAINER_APP.VIEW],
    element: <Detail />,
  },
];
