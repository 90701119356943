import { RoutePermittedRole } from 'constants/AppConst';
import { ROUTE_STRING } from 'constants/RouteString';
import List from './List';

export const appPackageRoutes = [
  {
    permittedRole: RoutePermittedRole.User,
    path: [
      ROUTE_STRING.APP_PACKAGE.LIST,
      ROUTE_STRING.APP_PACKAGE.ADD,
      ROUTE_STRING.APP_PACKAGE.VIEW,
    ],
    element: <List />,
  },
];
