import jwtAxios from '@tenant/services/auth/jwt-auth';
import APIS from './apis';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getCategoryList = createAsyncThunk(
  APIS.GET_APP_CATEGORIES,
  async () => {
    const response = await jwtAxios.get(APIS.GET_APP_CATEGORIES);
    return response.data;
  },
);

const extraReducers = (builder) => {
  builder
    .addCase(getCategoryList.pending, (state) => {
      state.status = 'loading';
      state.loading = true;
    })
    .addCase(getCategoryList.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.categoryList = action.payload;
      state.loading = false;
    })
    .addCase(getCategoryList.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
      state.loading = false;
    });
};

export default extraReducers;
