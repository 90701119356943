import React, { useMemo } from 'react';
import { Grid } from '@mui/material';
import AppPreviewMarketPlace from '@tenant/core/AppPreviewMarketPlace';
import moment from 'moment';
import { PRICE_TYPE_ENUM } from '@tenant/utility/constants/enum';
import { numberToCurrency } from '@tenant/utility/helper/Utils';

const ConfirmStep = ({ values = {} }) => {
  const price = useMemo(
    () =>
      values.PriceType === PRICE_TYPE_ENUM['FREE']
        ? 'Free'
        : values.PriceType === PRICE_TYPE_ENUM['FLEXIBLE']
        ? 'PriceUponRequest'
        : `Monthly: ${numberToCurrency(
            parseFloat(values.MonthlyPrice),
          )} | Annual: ${numberToCurrency(parseFloat(values.AnnuallyPrice))}`,
    [values.PriceType, values.MonthlyPrice, values.AnnuallyPrice],
  );
  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{
            '& .img-col': { minWidth: 125 },
            '& .content-col': {
              width: 'calc(100% - 125px)',
            },
          }}
        >
          <Box className='img-col'>
            {!!values.IconFile && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  padding: 2,
                  backgroundColor: '#f5f5f5',
                  width: '100%',
                  height: '125px',
                  backgroundImage: `url(${URL.createObjectURL(
                    values.IconFile,
                  )})`,
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  borderRadius: 2,
                }}
              ></Box>
            )}
          </Box>
          <Box className='content-col'>
            <Typography variant='h4' mb={2}>
              {values?.Name}
            </Typography>
            <Typography variant='caption'>By Joblogic Marketplace</Typography>
            <Typography
              mb={1}
              variant='caption'
              component='p'
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {values?.ShortDescription}
            </Typography>
            {!!values?.KeyLabels && (
              <Chip
                size='small'
                label={values?.KeyLabels}
                color='success'
                variant='outlined'
              />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box dangerouslySetInnerHTML={{ __html: values.LongDescription }}></Box>
      </Grid> */}
      <Grid item xs={12}>
        <AppPreviewMarketPlace
          isRating={false}
          iconBlobReference={
            values.IconFile && URL.createObjectURL(values.IconFile)
          }
          appUrl={values.IconBlobReference}
          shortDescription={values.ShortDescription}
          name={values.Name}
          keyLabels={values.KeyLabels}
          longDescription={values.LongDescription}
          keyBenefits={values.KeyBenefits}
          appVersion={values.AppVersionNumber}
          mobileAppVersion={values.MobileAppVersion}
          lastUpdated={new moment()}
          totalRating={values.TotalRating}
          categories={values.CategoryIds}
          dependencies={values.Dependencies}
          priceType={Object.keys(PRICE_TYPE_ENUM).find(
            (key) => PRICE_TYPE_ENUM[key] === values.PriceType,
          )}
          price={price}
        />
      </Grid>
    </Grid>
  );
};

export default React.memo(ConfirmStep);

ConfirmStep.propTypes = {};
