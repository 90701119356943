import React from 'react';
import PropTypes from 'prop-types';
import useUpgradeVersionAppPackage from 'redux/slices/appPackage/hooks/useUpgradeVersionAppPackage';
import { LoadingButton } from '@mui/lab';
import useModal from '@tenant/utility/hooks/useModal';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import UpgradeIcon from '@mui/icons-material/Upgrade';

const UpgradeVersionButton = ({ appPackageId, callbackSuccess }) => {
  const upgradeMutate = useUpgradeVersionAppPackage();
  const { visible, onShow, onClose } = useModal();
  const handleSave = () => {
    upgradeMutate.mutate(
      { Id: appPackageId },
      {
        onSuccess(data) {
          onClose();
          callbackSuccess?.(data);
        },
      },
    );
  };
  return (
    <>
      <LoadingButton
        loading={upgradeMutate.isPending}
        variant='outlined'
        startIcon={<UpgradeIcon />}
        onClick={onShow}
      >
        Upgrade{' '}
      </LoadingButton>

      <Dialog open={visible} onClose={onClose} maxWidth='sm' fullWidth>
        <DialogTitle fontSize={16}>Confirm Upgrade Version</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-upgrade-version-dialog-description'>
            Warning: This action cannot be undone. Are you sure you want to
            upgrade to latest this App Package?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} loading={upgradeMutate.isPending}>
            Cancel
          </Button>
          <LoadingButton
            loading={upgradeMutate.isPending}
            variant='contained'
            color='primary'
            onClick={handleSave}
          >
            Upgrade
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(UpgradeVersionButton);

UpgradeVersionButton.propTypes = {
  appPackageId: PropTypes.string.isRequired,
  callbackSuccess: PropTypes.func,
};
