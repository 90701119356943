import React, { useId } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Checkbox, CircularProgress } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useQuery } from '@tanstack/react-query';
import { getListAppPackageQuery } from 'redux/slices/appPackage/apis';
const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const FIELD_NAME = 'dockerImageList';
const KEY = 'Id';
const LABEL = 'Name';

const AppPackageSelect = (props) => {
  const {
    error,
    helperText,
    value = [],
    required,
    handleInputChange,
    excludes,
    textFieldProps,
    isMultiple = false,
    queryKey = '',
    ...rest
  } = props;

  const { data, isLoading } = useQuery({
    queryKey: ['app-package-list-select' + queryKey],
    queryFn: getListAppPackageQuery,
  });

  const id = useId();

  return (
    <Autocomplete
      fullWidth
      multiple={isMultiple}
      disableCloseOnSelect={isMultiple}
      value={value}
      id={`${FIELD_NAME}-select-${id}`}
      name={FIELD_NAME}
      options={
        data?.data?.filter((e) => !excludes?.some((f) => f[KEY] === e[KEY])) ??
        []
      }
      getOptionLabel={(option) => option[LABEL]}
      isOptionEqualToValue={(option, value) => {
        return option?.[KEY] === value?.[KEY];
      }}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;
        return (
          <li {...optionProps} key={option?.[KEY]}>
            {isMultiple && (
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
            )}
            {option[LABEL]}
          </li>
        );
      }}
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          error={error}
          helperText={helperText}
          placeholder={
            isMultiple ? 'Select App Packages' : 'Select App Package'
          }
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          {...textFieldProps}
        />
      )}
      {...rest}
    />
  );
};

export default React.memo(AppPackageSelect);
