import { Box, Grid, Stack, Typography, List, Button } from '@mui/material';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import useGetAppRatings from 'pages/listApp/hooks/useGetAppRatings';
import RowStar from './RowStar';
import Comment from './Comment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const TabReview = () => {
  const { appId } = useParams();
  const [orderBy, setOrderBy] = React.useState(1);
  const { data } = useGetAppRatings({
    appId,
    orderBy,
  });

  const dataStar = useMemo(() => {
    return [5, 4, 3, 2, 1].map((star) => ({
      star,
      value:
        data?.AppRatings.length !== 0
          ? (data?.AppRatings.reduce((total, item) => {
              if (item.ReviewScore === star) total++;
              return total;
            }, 0) *
              100) /
            data?.AppRatings.length
          : 0,
    }));
  }, [data]);

  const totalRating = useMemo(() => {
    return data?.AppRatings
      ? data?.AppRatings.reduce((total, item) => total + item.ReviewScore, 0) / data?.AppRatings.length
      : 0;
  }, [data]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Stack direction='row' spacing={8} alignItems='center'>
            <Stack direction='column' spacing={1}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='h1' sx={{ fontSize: 32 }}>
                  {totalRating || 0}
                </Typography>
                <StarIcon fontSize='large' />
              </Stack>
              <Typography variant='caption'>
                {data?.AppRatings.length}{' '}
                {data?.AppRatings.length < 2 ? 'rating' : 'ratings'}{' '}
              </Typography>
            </Stack>
            <Stack direction='column' spacing={1} sx={{ width: '100%' }}>
              {dataStar.map((item, index) => (
                <RowStar key={index} star={item.star} value={item.value} />
              ))}
            </Stack>
          </Stack>
          <Box sx={{ mt: 4 }}>
            <Typography variant='h5'>
              Sort by:
              <Button
                variant='text'
                size='small'
                endIcon={
                  orderBy === 1 ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )
                }
                onClick={() => setOrderBy(orderBy === 1 ? 2 : 1)}
              >
                Newest to Oldest
              </Button>
            </Typography>
            <List>
              {data?.AppRatings.map((item) => (
                <Comment
                  key={item.Id}
                  id={item.Id}
                  userAvatar={item.UserProfileImageUrl}
                  userName={item.UserName}
                  commentText={item.Comment}
                  rating={item.ReviewScore}
                  reviewDate={item.ReviewDate}
                  isHidden={item.IsHidden}
                />
              ))}
            </List>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TabReview;
