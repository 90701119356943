import { useQuery } from '@tanstack/react-query';
const { getBAUModules } = require('api/appManager');
const { LIST_BAU_MODULES_QUERY } = require('constants/QueryString');

const useGetBAUModulesQuery = () => {
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: [LIST_BAU_MODULES_QUERY],
    queryFn: async () => {
      try {
        return await getBAUModules();
      } catch (error) {
        throw error;
      }
    },
  });

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useGetBAUModulesQuery;
