import {
  MdOutlineStorefront,
  MdOutlineCloudUpload,
  MdOutlineBackpack,
  MdApproval,
} from 'react-icons/md';
import { ROUTE_STRING } from 'constants/RouteString';
import { DiDocker } from 'react-icons/di';

const routesConfig = [
  {
    id: 'app',
    title: 'Developer',
    messageId: 'Developer',
    type: 'group',
    children: [
      {
        id: 'Apps',
        title: 'Apps',
        messageId: 'Apps',
        icon: <MdOutlineStorefront />,
        type: 'item',
        url: ROUTE_STRING.LIST_APP.LIST,
      },
      {
        id: 'CreateApp',
        title: 'Create App',
        messageId: 'CreateApp',
        icon: <MdOutlineCloudUpload />,
        type: 'item',
        url: ROUTE_STRING.PUBLISH_APP,
      },
    ],
  },

  {
    id: 'Library',
    title: 'Library',
    messageId: 'Library',
    type: 'group',
    children: [
      {
        id: 'DockerRepositories',
        title: 'Docker Image',
        messageId: 'DockerRepositories',
        icon: <DiDocker />,
        type: 'item',
        url: ROUTE_STRING.DOCKER_IMAGE.LIST,
      },
      {
        id: 'AppPackage',
        title: 'App Package',
        messageId: 'AppPackage',
        icon: <MdOutlineBackpack />,
        type: 'item',
        url: ROUTE_STRING.APP_PACKAGE.LIST,
      },
      {
        id: 'AppInstance',
        title: 'App Instance',
        messageId: 'AppInstance',
        icon: <MdApproval />,
        type: 'item',
        url: ROUTE_STRING.APP_INSTANCE.LIST,
      },
    ],
  },
];

export default routesConfig;
