import React from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const AppAccordion = ({ title, children }) => {
  return (
    <Accordion
      defaultExpanded
      sx={{
        boxShadow: 'none',
        backgroundColor: grey[50],
        pl: 5,
        pr: 5,
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant='h4'> {title} </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default React.memo(AppAccordion);

AppAccordion.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
};
