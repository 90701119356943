import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import UserRoleModal from './UserRoleModal';
import usePermissionService from '../hooks/usePermissionService';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from '@tenant/utility/constants/default';
import EditIcon from '@mui/icons-material/Edit';
import useModal from '@tenant/utility/hooks/useModal';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import UserRoleDeleteConfirm from './UserRoleDeleteConfirm';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import SearchPermission from './SearchPermission';
import useNextTick from '@tenant/utility/hooks/useNextTick';

const UserRole = ({
  initialRole,
  role,
  hideColumns = [],
  canEdit = false,
  canRemove = false,
  isDetail = false,
  callbackRemove = () => {},
}) => {
  const { visible, onClose, onShow } = useModal();
  const {
    visible: visibleConfirm,
    onClose: onCloseConfirm,
    onShow: onShowConfirm,
  } = useModal();
  const [pageIndex, setPageIndex] = useState(DEFAULT_PAGE_INDEX);
  const [userRole, setUserRole] = useState();
  const [userRoleListRoot, setUserRoleListRoot] = useState([]);
  const [userRoleList, setUserRoleList] = useState([]);
  const [search, setSearch] = useState('');
  const {
    updateUserRole,
    removeUserRole,
    removeAllUserRoles,
    assisgnToUsers,
    loading,
    loadingBtn,
    getAllUserAndRoles,
  } = usePermissionService();

  const handleChangeSearch = useCallback((value, listCompare) => {
    setSearch(value);
    if (value) {
      setUserRoleList(
        listCompare.filter(
          (e) => e.UserId.toLowerCase().indexOf(value.toLowerCase()) >= 0,
        ),
      );
      return;
    }
    setUserRoleList(deepClone(listCompare));
  }, []);

  const callBackGetAllUsers = useCallback(
    async (data) => {
      setUserRoleListRoot(deepClone(data?.Items));
      setSearch('');
      setUserRoleList(deepClone(data?.Items));
    },
    [search],
  );

  const onSubmit = useCallback(
    (data, callback) => {
      if (isDetail) {
        assisgnToUsers({
          payload: {
            RoleId: data.RoleIds?.[0],
            UserEmails: data.UserIds?.length > 0 ? data.UserIds : [data.UserId],
          },
          onSuccess: () => {
            callbackRemove();
            callback?.();
            setSearch('');
          },
        });
        return;
      }

      updateUserRole(
        { ...data, UserIds: data.UserIds || [data.UserId] },
        () => {
          getAllUserAndRoles({ pageIndex }, callBackGetAllUsers);
          callback?.();
        },
      );
    },
    [callBackGetAllUsers],
  );

  const handleShowModal = useCallback(
    (userRole) => {
      if (userRole) {
        setUserRole(
          deepClone({
            ...userRole,
            RoleIds: userRole.Roles.map((e) => e.RoleId),
          }),
        );
        onShow();
      }
    },
    [onShow],
  );

  const handleCloseModal = useCallback(() => {
    setUserRole();
    onClose();
  }, [onClose]);

  const handleShowConfirmModal = useCallback(
    (userRole) => {
      if (userRole) {
        setUserRole(userRole);
        onShowConfirm();
      }
    },
    [onShowConfirm],
  );

  const handleCloseConfirmModal = useCallback(() => {
    setUserRole();
    onCloseConfirm();
  }, [onCloseConfirm]);

  const checkIsHideColumn = useCallback(
    (field = '') => {
      return hideColumns?.some((e) => e === field);
    },
    [hideColumns],
  );

  const columns = useMemo(
    () => [
      {
        field: 'UserId',
        headerName: 'Email',
        width: 300,
        flex: 1,
        hide: checkIsHideColumn('UserId'),
      },
      {
        field: 'Roles',
        headerName: 'Roles',
        width: 300,
        hide: checkIsHideColumn('Roles'),
        renderCell: (params) =>
          params.row.Roles?.length
            ? params.row.Roles.map((e) => e.Name).join(', ')
            : '',
      },
      {
        field: 'Action',
        headerName: 'Action',
        width: 100,
        hide: checkIsHideColumn('Action'),
        renderCell: (params) => (
          <>
            {canEdit && (
              <Tooltip title='Edit'>
                <IconButton
                  color='primary'
                  onClick={() => handleShowModal(params.row)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}

            {canRemove && (
              <Tooltip title='Remove'>
                <IconButton
                  color='error'
                  onClick={() => handleShowConfirmModal(params.row)}
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            )}
          </>
        ),
      },
    ],
    [handleShowModal, handleShowConfirmModal],
  );

  useEffect(() => {
    if (!isDetail) {
      getAllUserAndRoles({ pageIndex }, callBackGetAllUsers);
      return;
    }
    const newData = role?.UserEmails?.map((e) => ({ UserId: e }));
    setUserRoleList(deepClone(newData));
    setUserRoleListRoot(deepClone(newData));
  }, [getAllUserAndRoles, role?.UserEmails]);

  const onDelete = useCallback(
    (item) => {
      if (isDetail) {
        removeUserRole(
          {
            userId: item.userId,
            roleIds: [role?.RoleId],
          },
          () => {
            callbackRemove();
            handleCloseConfirmModal();
          },
        );
        return;
      }

      removeAllUserRoles(item, () => {
        getAllUserAndRoles({ pageIndex }, callBackGetAllUsers);
        handleCloseConfirmModal();
      });
    },
    [removeUserRole],
  );

  const handleChangePage = useCallback(
    (event, value) => {
      if (pageIndex === value) {
        return;
      }
      setPageIndex(value);
      getAllUserAndRoles({ pageIndex: value });
    },
    [getAllUserAndRoles, pageIndex],
  );

  return (
    <Box sx={{ position: 'relative', height: 'calc(100vh - 225px)' }}>
      <Box sx={{ height: 'calc(100vh - 325px)' }}>
        <UserRoleDeleteConfirm
          visible={visibleConfirm}
          onClose={handleCloseConfirmModal}
          userRole={userRole}
          onDelete={onDelete}
          loading={loadingBtn}
        />

        <UserRoleModal
          initialRole={initialRole}
          initialState={userRole}
          visible={visible}
          onClose={handleCloseModal}
          onSubmit={onSubmit}
          loading={loadingBtn}
          isDetail={isDetail}
        />

        <Box display={'flex'} justifyContent={'space-between'} mb={3}>
          <Box>
            <SearchPermission
              searchValue={search ?? ''}
              onChange={(e) =>
                handleChangeSearch(
                  e.target.value,
                  userRoleListRoot?.length > 0
                    ? userRoleListRoot
                    : role?.UserEmails?.map((e) => ({
                        UserId: e,
                      })),
                )
              }
            />
          </Box>
          <Button variant='contained' onClick={onShow}>
            Assignment
          </Button>
        </Box>
        <DataGrid
          hideFooter
          disableColumnSelector
          loading={loading}
          columns={columns}
          rows={userRoleList ?? []}
          getRowId={(row) => row.UserId}
        />
      </Box>
    </Box>
  );
};

export default React.memo(UserRole);

UserRole.propTypes = {};
