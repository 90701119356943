import API_STRINGS from 'constants/Api';
import jwtAxios from '@tenant/services/auth/jwt-auth';

export const getApps = async (query) => {
  const response = await jwtAxios.get(API_STRINGS.APP_MANAGER.GET_LIST, {
    params: query,
  });
  return response.data;
};

export const getAppDetail = async (query) => {
  const response = await jwtAxios.get(
    API_STRINGS.APP_MANAGER.GET_DETAIL + '/' + query?.appId,
  );
  return response.data;
};

export const getAppCategories = async (query) => {
  const response = await jwtAxios.get(API_STRINGS.APP_MANAGER.GET_CATEGORIES);
  return response.data;
};

export const publishApp = async (data) => {
  const response = await jwtAxios.post(
    API_STRINGS.APP_MANAGER.PUBLISH_APP + '/' + data?.AppId,
    data,
  );
  return response.data;
};

export const createApp = async (data) => {
  const response = await jwtAxios.post(
    API_STRINGS.APP_MANAGER.CREATE_APP,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const editApp = async (data) => {
  const response = await jwtAxios.post(
    API_STRINGS.APP_MANAGER.EDIT_APP + '/' + data?.AppId,
    data,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return response.data;
};

export const toggleShowReviews = async (data) => {
  const response = await jwtAxios.post(
    API_STRINGS.APP_MANAGER.TOGGLE_SHOW_REVIEWS,
    data,
  );
  return response.data;
};

export const getBAUModules = async (query) => {
  const response = await jwtAxios.get(API_STRINGS.APP_MANAGER.GET_BAU_MODULES, {
    params: query,
  });

  const mappedResponse = response.data.map((item) => ({
    id: item.Id,
    name: item.Name,
  }));
  return mappedResponse;
};
