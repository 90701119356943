import { Box, Stack, TextField } from "@mui/material";
import { memo } from "react";

const AppVersion = ({
  title,
  fieldKey,
  errorText,
  valueText,
  setFieldValue,
}) => {
  return (
    <Stack direction='column' spacing={2}>
      <Box component='p' color='text.primary' fontSize={14}>
        {title}
        <Box component='span' ml={1} sx={{ color: 'error.main' }}>
          *
        </Box>
      </Box>
      <TextField
        placeholder='1.0.0'
        error={!!errorText}
        helperText={errorText}
        value={valueText ?? ''}
        onChange={async (e) => {
          setFieldValue(fieldKey, e.target.value);
        }}
        onBlur={(e) => {
          setFieldValue(fieldKey, valueText?.trim());
        }}
        fullWidth
      />
    </Stack>
  );
};

export default memo(AppVersion);
