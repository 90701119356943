import { RoutePermittedRole } from 'constants/AppConst';
import ListApp from './ListApp';
import { ROUTE_STRING } from 'constants/RouteString';
import Edit from './components/Edit';

export const listAppRoutes = [
  {
    permittedRole: RoutePermittedRole.User,
    path: [ROUTE_STRING.LIST_APP.LIST, ROUTE_STRING.LIST_APP.DETAIL],
    element: <ListApp />,
  },
  {
    permittedRole: RoutePermittedRole.User,
    path: [ROUTE_STRING.LIST_APP.EDIT],
    element: <Edit />,
  },
];
