import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import { Formik } from 'formik';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { LoadingButton } from '@mui/lab';
import validationSchema from './constants/validationSchema';
import DockerAppTypes from 'constants/DockerAppTypes';
import checkIsRequireTargetPort from './helpers/checkIsRequireTargetPort';
import { grey } from '@mui/material/colors';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AppGlossary from '@tenant/core/App/Common/AppGlossary';
import useAddDockerImage from 'redux/slices/dockerImage/hooks/useAddDockerImage';
import SimpleBarReact from 'simplebar-react';

const Add = () => {
  const navigate = useNavigate();
  const onClickBackButton = () => {
    navigate(ROUTE_STRING.DOCKER_IMAGE.LIST);
  };
  const addItem = useAddDockerImage();

  const handleSubmit = (data) => {
    addItem.mutate(
      {
        ...data,
        DeploymentType: data.DeploymentType?.Value,
        TargetPort: +data.TargetPort,
        RequiredEnviromentVariables: data.RequiredEnviromentVariables.split(
          /\r?\n/,
        )
          .map((line) => line.trim())
          .join(','),
      },
      {
        onSuccess() {
          onClickBackButton();
        },
      },
    );
  };

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
          </Box>
        </Box>
      </AppsHeader>

      <Box p={4}>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{ DeploymentType: DockerAppTypes[0] }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm, values, setFieldValue, errors }) => (
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  height: 'calc(100vh - 285px)',
                  overflow: 'auto',
                  p: 4,
                  ml: -4,
                  mr: -4,
                }}
              >
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h3' mb={4}>
                      Image Info
                    </Typography>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Image Name
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      value={values.ImageName ?? ''}
                      error={!!errors.ImageName}
                      helperText={errors.ImageName}
                      onChange={(e) =>
                        setFieldValue('ImageName', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Deployment Type
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <Autocomplete
                      disablePortal
                      value={values.DeploymentType ?? null}
                      options={DockerAppTypes}
                      getOptionLabel={(row) => row.Description}
                      isOptionEqualToValue={(option, value) => {
                        return option?.Value === value?.Value;
                      }}
                      renderOption={(props, option) => (
                        <Box
                          component='li'
                          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                          {...props}
                          key={option.Value}
                        >
                          {option.Description}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={!!errors.DeploymentType}
                          helperText={errors.DeploymentType}
                        />
                      )}
                      onChange={(e, value) => {
                        setFieldValue('DeploymentType', value);
                        if (value?.IsCron) {
                          setFieldValue('TargetPort', '');
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Target Port
                      {checkIsRequireTargetPort(values.DeploymentType) && (
                        <Box
                          component='span'
                          ml={1}
                          sx={{ color: 'error.main' }}
                        >
                          *
                        </Box>
                      )}
                    </Box>
                    <TextField
                      type='number'
                      disabled={
                        !checkIsRequireTargetPort(values.DeploymentType)
                      }
                      fullWidth
                      value={values.TargetPort ?? ''}
                      error={!!errors.TargetPort}
                      helperText={errors.TargetPort}
                      onChange={(e) =>
                        setFieldValue('TargetPort', e.target.value)
                      }
                      sx={{ '& .Mui-disabled': { background: grey[50] } }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Required Environment Variables
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                      <AppGlossary title='Please break down your input if you have multiple values.' />
                    </Box>
                    <TextField
                      fullWidth
                      multiline
                      rows={5}
                      value={values.RequiredEnviromentVariables ?? ''}
                      error={!!errors.RequiredEnviromentVariables}
                      helperText={errors.RequiredEnviromentVariables}
                      onChange={(e) =>
                        setFieldValue(
                          'RequiredEnviromentVariables',
                          e.target.value,
                        )
                      }
                      sx={{ '& .Mui-disabled': { background: grey[50] } }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Typography variant='h3' mb={4} mt={4}>
                      Image Source
                    </Typography>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Branch Name
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      value={values.BranchName ?? ''}
                      error={!!errors.BranchName}
                      helperText={errors.BranchName}
                      onChange={(e) =>
                        setFieldValue('BranchName', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Repository Url
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      value={values.RepositoryUrl ?? ''}
                      error={!!errors.RepositoryUrl}
                      helperText={errors.RepositoryUrl}
                      onChange={(e) =>
                        setFieldValue('RepositoryUrl', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Dockerfile Path
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      value={values.DockerfilePath ?? ''}
                      error={!!errors.DockerfilePath}
                      helperText={errors.DockerfilePath}
                      onChange={(e) =>
                        setFieldValue('DockerfilePath', e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Access token
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      type={values.ShowPassword ? 'text' : 'password'}
                      value={values.AccessToken ?? ''}
                      error={!!errors.AccessToken}
                      helperText={errors.AccessToken}
                      onChange={(e) =>
                        setFieldValue('AccessToken', e.target.value)
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() =>
                                setFieldValue(
                                  'ShowPassword',
                                  !values.ShowPassword,
                                )
                              }
                            >
                              {values.ShowPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>

              <FooterWrapper
                className='footer'
                sx={{
                  margin: '0px -20px 0px -20px',
                  backgroundColor: '#f4f7fe',
                  height: '50px',
                }}
              >
                <Stack
                  direction='row'
                  justifyContent='end'
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    height: '100%',
                    p: 2,
                  }}
                >
                  <Button
                    variant='outlined'
                    onClick={onClickBackButton}
                    sx={{ mr: 3 }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant='contained'
                    loading={addItem.isPending}
                    sx={{ mr: 3 }}
                    onClick={submitForm}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </FooterWrapper>
            </Box>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Add;
