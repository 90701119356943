import React, { useMemo, useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { Box, Button, IconButton, Pagination, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ROUTE_STRING } from 'constants/RouteString';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { PAGE_SIZE } from 'constants/Common';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppSearch from '@tenant/core/AppSearchBar';
import useGetListDockerImage from 'redux/slices/dockerImage/hooks/useGetListDockerImage';
import DockerAppTypes from 'constants/DockerAppTypes';
import { useDispatch, useSelector } from 'react-redux';
import { removeItem, viewItem } from 'redux/slices/dockerImage';
import {
  DEFAULT_PAGE_INDEX,
  DEFAULT_PAGE_SIZE,
} from '@tenant/utility/constants/default';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
import useModal from '@tenant/utility/hooks/useModal';
import { deepClone } from '@tenant/utility/helper/Utils';
import useDeleteDockerImage from 'redux/slices/dockerImage/hooks/useDeleteDockerImage';
import DeleteDialog from '@tenant/core/App/Dialog/DeleteDialog';

const DockerImageTable = () => {
  const { list } = useSelector((s) => s.dockerImage);
  console.log('🚀 ~ DockerImageTable ~ list:', list);
  const [pageIndex, setPageIndex] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading } = useGetListDockerImage();
  const totalCount = list?.length ?? 0;
  const navigate = useNavigate();
  const {
    visible: visibleDelete,
    onShow: onShowDelete,
    onClose: onCloseDelete,
  } = useModal();
  const deleteMutate = useDeleteDockerImage();
  const [selected, setSelected] = useState();
  const rows = useMemo(
    () =>
      list
        ?.slice(
          (pageIndex - 1) * DEFAULT_PAGE_SIZE,
          DEFAULT_PAGE_SIZE * pageIndex,
        )
        ?.filter(
          (e) =>
            e.ImageName?.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0,
        ) ?? [],
    [searchTerm, list, pageIndex],
  );
  const dispatch = useDispatch();

  const onViewItem = (item) => {
    dispatch(viewItem(item));
  };

  const clearSearchTerm = () => {
    setSearchTerm('');
  };

  const onChangeSearch = (e) => {
    setSearchTerm(e.target.value);
    setPageIndex(DEFAULT_PAGE_INDEX);
  };

  const handleShowDelete = (item) => {
    setSelected(deepClone(item));
    onShowDelete();
  };

  const handleCloseDelete = () => {
    setSelected();
    onCloseDelete();
  };

  const handleDelete = () => {
    deleteMutate.mutate(selected, {
      onSuccess() {
        dispatch(removeItem(selected));
        handleCloseDelete();
      },
    });
  };

  const columns = [
    {
      field: 'ImageName',
      headerName: 'Image Name',
      width: 450,
      sortable: true,
      flex: 1,
    },
    {
      field: 'DeploymentType',
      headerName: 'Deployment Type',
      width: 300,
      sortable: true,
      renderCell: (params) => (
        <Box
          component={'span'}
          sx={{ width: '100%', display: 'block' }}
          className='MuiDataGrid-cellContent'
        >
          {DockerAppTypes.find((e) => e.Value === params.value)?.Description}
        </Box>
      ),
    },
    {
      field: 'Action',
      headerName: '',
      width: 50,
      type: 'actions',
      getActions: (params) => [
        <GridActionsCellItem
          sx={{ minWidth: 150 }}
          key='action--view'
          label='View'
          showInMenu
          onClick={() => {
            onViewItem(params.row);
            navigate(ROUTE_STRING.DOCKER_IMAGE.VIEW);
          }}
        />,
        <GridActionsCellItem
          key='action--delete'
          label='Delete'
          showInMenu
          onClick={() => {
            handleShowDelete(params.row);
          }}
        />,
      ],
    },
  ];

  const onPageChange = (value) => {
    setPageIndex(value);
  };

  const goToAdd = () => {
    navigate(ROUTE_STRING.DOCKER_IMAGE.ADD);
  };

  return (
    <>
      <DeleteDialog
        visible={visibleDelete}
        loading={deleteMutate.isPending}
        handleClose={handleCloseDelete}
        handleSave={handleDelete}
      />

      <AppsHeader>
        <Box
          flex={1}
          display='flex'
          justifyContent='space-between'
          alignItems='center'
        >
          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <AppSearch
              isClearable
              searchValue={searchTerm}
              onClearSearch={clearSearchTerm}
              iconPosition='right'
              id='outlined-adornment-weight'
              size='small'
              overlap={false}
              onChange={onChangeSearch}
            ></AppSearch>
          </Box>
          <Button variant='outlined' onClick={goToAdd}>
            Add New
          </Button>
        </Box>
      </AppsHeader>
      <Box style={{ height: 'calc(100vh - 270px)', width: '100%' }}>
        <DataGrid
          hideFooter
          disableColumnSelector
          disableColumnMenu
          rows={rows}
          columns={columns}
          getRowId={(row) => row.Id}
          loading={isLoading}
        />
      </Box>

      <FooterWrapper className='footer'>
        <Box justifyContent={'center'} className='footerContainer'>
          <Pagination
            count={Math.ceil(totalCount / PAGE_SIZE) || 1}
            page={pageIndex}
            onChange={(e, v) => onPageChange(v)}
            showFirstButton
            showLastButton
          />
          <Typography>Total: {totalCount || 0}</Typography>
        </Box>
      </FooterWrapper>
    </>
  );
};

export default DockerImageTable;
