export const getBlobFileImage = (file, callback) => {
  const reader = new FileReader();

  reader.onload = function (e) {
    const arrayBuffer = e.target.result;
    const blob = new Blob([arrayBuffer], { type: file.type });
    callback?.(blob);
  };

  reader.readAsArrayBuffer(file);
};

export const convertToDecimal = (value, decimal = 2) => {
  // Remove non-numeric characters except for dots
  const normalized = value.replace(/[^0-9.]/g, '');

  // Handle multiple dots
  const parts = normalized.split('.');
  if (parts.length > 2) {
    return parts[0] + '.' + parts.slice(1).join('');
  }

  return normalized;
};
