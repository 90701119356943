import {
  PLATFORM_ENUM,
  PRICE_TYPE_ENUM,
  APP_CONNECTIVITY_STATE_ENUM,
  APP_TYPE_ENUM,
} from '@tenant/utility/constants/enum';
import * as Yup from 'yup';

import { StepInformation, StepKeyBenefit, StepMobile } from './constants';

export const validateStepInformation = {
  [StepInformation]: Yup.object().shape({
    Name: Yup.string().required('Name app is required'),
    AppType: Yup.string(),
    AppUrl: Yup.string(),
    AnnuallyPrice: Yup.string()
      .when('PriceType', {
        is: (val) => val === PRICE_TYPE_ENUM['SUBSCRIPTION'], // If fieldA has a value
        then: Yup.string().required(),
        otherwise: Yup.string(), // Otherwise, fieldB is not required
      })
      .label('Annual Price'),
    MonthlyPrice: Yup.string()
      .when('PriceType', {
        is: (val) => val === PRICE_TYPE_ENUM['SUBSCRIPTION'], // If fieldA has a value
        then: Yup.string().required(),
        otherwise: Yup.string(), // Otherwise, fieldB is not required
      })
      .label('Monthly Price'),
    ShortDescription: Yup.string().required('Short description is required'),
    LongDescription: Yup.string().required().label('Long Description'),
    KeyLabels: Yup.string().required('Key Labels is required'),
    LaunchType: Yup.number().required('LaunchType is required'),
    IsLegacy: Yup.bool().nullable(),
    IconBlobReference: Yup.string().required().label('Icon'),
    AuthorId: Yup.number().nullable(),
    Status: Yup.number().nullable(),
    CategoryIds: Yup.array().min(1).required().label('Categories'),
    PriceType: Yup.number().required().label('Price Type'),
    AuthorName: Yup.string().nullable(),
    AuthorEmail: Yup.string().when('AuthorName', {
      is: (val) => !!val, // If fieldA has a value
      then: Yup.string().required('Author Email is required'),
      otherwise: Yup.string(), // Otherwise, fieldB is not required
    }),
    ModuleId: Yup.number().when(['IntegrationMode'], {
      is: (integrationMode) => {
        const isModule = integrationMode === APP_TYPE_ENUM['Module'];
        return isModule;
      },
      then: Yup.number().required(),
      otherwise: Yup.number().nullable(),
    }),
  }),
  [StepMobile]: Yup.object().shape({
    AppConnectivityState: Yup.number().when(['Platform', 'IntegrationMode'], {
      is: (Platform, IntegrationMode) => {
        const isSupportMobile =
          Platform === PLATFORM_ENUM['Mobile'] ||
          Platform === PLATFORM_ENUM['Cross_Platform'];
        const shouldRequiredIntegrationMode =
          IntegrationMode !== APP_TYPE_ENUM['Module'];
        return isSupportMobile && shouldRequiredIntegrationMode;
      },
      then: Yup.number().required('Connectivity State is required'),
      otherwise: Yup.number().nullable(),
    }),
    MobileAppCode: Yup.string().when(['Platform'], {
      is: (Platform) => {
        const isSupportMobile =
          Platform === PLATFORM_ENUM['Mobile'] ||
          Platform === PLATFORM_ENUM['Cross_Platform'];
        return isSupportMobile;
      },
      then: Yup.string()
        .matches(
          /^[{]?[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}[}]?$/,
          'Invalid Mobile App Code format',
        )
        .required('Mobile Feature Code is required'),
      otherwise: Yup.string().nullable(),
    }),
    BundleZipBlobReference: Yup.string().when(
      ['Platform', 'AppConnectivityState', 'MobileAppUrl'],
      {
        is: (Platform, AppConnectivityState, MobileAppUrl) => {
          const isSupportMobile =
            Platform === PLATFORM_ENUM['Mobile'] ||
            Platform === PLATFORM_ENUM['Cross_Platform'];
          const isSupportOffline =
            AppConnectivityState === APP_CONNECTIVITY_STATE_ENUM['Offline'];

          if (isSupportOffline && MobileAppUrl) {
            return false;
          }
          return isSupportMobile && isSupportOffline;
        },
        then: Yup.string().required('Bundle zip file is required'),
        otherwise: Yup.string(),
      },
    ),
    MobileFeatureUrl: Yup.string().when(['Platform', 'AppConnectivityState'], {
      is: (Platform, AppConnectivityState) => {
        const isSupportMobile =
          Platform === PLATFORM_ENUM['Mobile'] ||
          Platform === PLATFORM_ENUM['Cross_Platform'];
        const isSupportOnline =
          AppConnectivityState === APP_CONNECTIVITY_STATE_ENUM['Online'];
        return isSupportMobile && isSupportOnline;
      },
      then: Yup.string().required('Mobile App URL is required'),
      otherwise: Yup.string(),
    }),
  }),
  [StepKeyBenefit]: Yup.object().shape({
    KeyBenefits: Yup.string().required('Key benefits is required'),
  }),
};
