import { useQuery } from '@tanstack/react-query';
import { getRatingApps } from 'api/ratingManager';
import { LIST_APP_RATINGS_QUERY } from 'constants/QueryString';

const useGetAppRatings = (query) => {
  const { data, isLoading, refetch, error } = useQuery({
    queryKey: [LIST_APP_RATINGS_QUERY, query],
    queryFn: async () => {
      try {
        return await getRatingApps(query);
      } catch (error) {
        throw error;
      }
    },
    enabled: !!query.appId,
  });

  return {
    data,
    isLoading,
    refetch,
    error,
  };
};

export default useGetAppRatings;