import { Box, Button, Grid, Stack, Tab, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import { Formik } from 'formik';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import validationSchema from './constants/validationSchema';
import { grey } from '@mui/material/colors';
import useAddAppPackage from 'redux/slices/appPackage/hooks/useAddAppPackage';
import DockerImageCollapseCard from './components/Add/DockerImageCollapseCard';
import { deepClone } from '@mui/x-data-grid/utils/utils';

const Add = () => {
  const navigate = useNavigate();
  const [tab, setTab] = useState('1');
  const onClickBackButton = () => {
    navigate(ROUTE_STRING.APP_PACKAGE.LIST);
  };
  const addItem = useAddAppPackage();
  const [isValidAppContainer, setIsValidAppContainer] = useState(true);

  const handleSubmit = (data) => {
    if (!isValidAppContainer) {
      return;
    }

    addItem.mutate(
      {
        ...data,
        AppComponents: data?.AppComponents?.map((e) => ({
          Name: e.Name,
          ImageName: e.ImageName,
        })),
      },
      {
        onSuccess(result) {
          if (!result?.data?.Id) return;
          navigate(
            ROUTE_STRING.APP_PACKAGE.VIEW.replace(':id', result?.data?.Id),
          );
        },
      },
    );
  };

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
          </Box>
        </Box>
      </AppsHeader>

      <Box p={4}>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{}}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm, values, setFieldValue, errors }) => (
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  height: 'calc(100vh - 285px)',
                  overflow: 'auto',
                }}
              >
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={tab}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList
                        onChange={handleChange}
                        aria-label='lab API tabs example'
                        sx={{
                          '& .information ': {
                            color:
                              (!!errors.Name || !!errors.Description) &&
                              '#d32f2f !important',
                          },
                          '& .containers ': {
                            color: !isValidAppContainer && '#d32f2f !important',
                          },
                        }}
                      >
                        <Tab
                          className='information'
                          label='Information'
                          value='1'
                        />
                        <Tab
                          className='containers'
                          label='Containers'
                          value='2'
                        />
                      </TabList>
                    </Box>
                    <Box mt={3}>
                      <TabPanel value='1'>
                        <Grid container spacing={5}>
                          <Grid item xs={12} sm={12}>
                            <Box
                              component='p'
                              color='text.primary'
                              fontSize={14}
                              mb={2}
                            >
                              Name
                              <Box
                                component='span'
                                ml={1}
                                sx={{ color: 'error.main' }}
                              >
                                *
                              </Box>
                            </Box>
                            <TextField
                              fullWidth
                              value={values.Name ?? ''}
                              error={!!errors.Name}
                              helperText={errors.Name}
                              onChange={(e) =>
                                setFieldValue('Name', e.target.value, true)
                              }
                            />
                          </Grid>

                          <Grid item xs={12} sm={12}>
                            <Box
                              component='p'
                              color='text.primary'
                              fontSize={14}
                              mb={2}
                            >
                              Description{' '}
                              <Box
                                component='span'
                                ml={1}
                                sx={{ color: 'error.main' }}
                              >
                                *
                              </Box>
                            </Box>
                            <TextField
                              fullWidth
                              multiline
                              rows={5}
                              value={values.Description ?? ''}
                              error={!!errors.Description}
                              helperText={errors.Description}
                              onChange={(e) =>
                                setFieldValue(
                                  'Description',
                                  e.target.value,
                                  true,
                                )
                              }
                              sx={{
                                '& .Mui-disabled': { background: grey[50] },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value='2'>
                        <DockerImageCollapseCard
                          isError={!isValidAppContainer}
                          initialValues={deepClone(values.AppComponents ?? [])}
                          handleChange={(newValue, isValid) => {
                            setFieldValue('AppComponents', deepClone(newValue));
                            setIsValidAppContainer(isValid);
                          }}
                        />
                      </TabPanel>
                    </Box>
                  </TabContext>
                </Box>
              </Box>

              <FooterWrapper
                className='footer'
                sx={{
                  margin: '0px -20px 0px -20px',
                  backgroundColor: '#f4f7fe',
                  height: '50px',
                }}
              >
                <Stack
                  direction='row'
                  justifyContent='end'
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    height: '100%',
                    p: 2,
                  }}
                >
                  <Button
                    variant='outlined'
                    onClick={onClickBackButton}
                    sx={{ mr: 3 }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant='contained'
                    loading={addItem.isPending}
                    sx={{ mr: 3 }}
                    onClick={submitForm}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </FooterWrapper>
            </Box>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Add;
