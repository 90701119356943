import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppsHeader from '@tenant/core/AppsContainer/AppsHeader';
import AppTooltip from '@tenant/core/AppTooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IntlMessages from '@tenant/utility/IntlMessages';
import { ROUTE_STRING } from 'constants/RouteString';
import { Formik } from 'formik';
import FooterWrapper from '@tenant/core/AppLayout/components/AppFooter/FooterWrapper';
import { LoadingButton } from '@mui/lab';
import validationSchema from './constants/validationSchema';
import { grey } from '@mui/material/colors';
import useAddAppInstance from 'redux/slices/appInstance/hooks/useAddAppInstance';
import AppPackageSelect from '@tenant/core/App/Select/AppPackageSelect';
import { DataGrid } from '@mui/x-data-grid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddEnvironmentDrawer from './components/Add/AddEnvironmentDrawer';
import useModal from '@tenant/utility/hooks/useModal';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const Add = () => {
  const { visible, onClose, onShow } = useModal();
  const [containerApp, setContainerApp] = useState();
  const navigate = useNavigate();
  const onClickBackButton = () => {
    navigate(ROUTE_STRING.APP_INSTANCE.LIST);
  };
  const addItem = useAddAppInstance();

  const handleSubmit = (data) => {
    const isValid = !data?.AppPackage?.AppComponents?.some((e) => !e.isValid);

    if (!isValid) return;

    const convertData = {
      ...data,
      RequiredEnviromentVariables: undefined,
      AppPackage: undefined,
      AppPackageId: data?.AppPackage?.Id,
      ContainerApps: data?.AppPackage?.AppComponents?.map((e) => ({
        Cron: e.Cron ?? undefined,
        ComponentName: e.Name,
        EnviromentVariables: e.Environments.reduce((acc, { key, value }) => {
          acc[key] = value;
          return acc;
        }, {}),
      })),
    };

    addItem.mutate(convertData, {
      onSuccess(result) {
        if (!result?.data?.Id)
          navigate(
            ROUTE_STRING.APP_INSTANCE.VIEW.replace(':id', result?.data?.Id),
          );
      },
    });
  };

  const handleShow = (item) => {
    setContainerApp({
      ...item,
    });
    onShow();
  };

  const handleClose = () => {
    setContainerApp(null);
    onClose();
  };

  const columns = [
    {
      field: 'Name',
      headerName: 'Name',
      width: 300,
      sortable: true,
      flex: 1,
    },
    {
      field: 'isValid',
      headerName: '',
      width: 100,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          {params.value ? (
            <Tooltip title='Valid'>
              <CheckCircleIcon color='success' />
            </Tooltip>
          ) : (
            <Tooltip title='Invalid'>
              <CancelIcon color='error' />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      field: 'Action',
      headerName: 'Action',
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <IconButton onClick={() => handleShow(params.row)}>
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <>
      <AppsHeader>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          sx={{ width: '100%' }}
        >
          <Box
            sx={{
              display: 'flex',
              cursor: 'pointer',
              alignItems: 'center',
              gap: 4,
            }}
            component='span'
            mr={{ xs: 2, sm: 4 }}
          >
            <AppTooltip title={<IntlMessages id='common.back' />}>
              <ArrowBackIcon
                sx={{
                  color: 'text.secondary',
                }}
                onClick={onClickBackButton}
              />
            </AppTooltip>
          </Box>
        </Box>
      </AppsHeader>

      <Box p={4}>
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          initialValues={{
            CpuAndMemory: 1,
            RequiredEnviromentVariables: [],
          }}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ submitForm, values, setFieldValue, errors }) => (
            <Box sx={{ position: 'relative' }}>
              <Box
                sx={{
                  height: 'calc(100vh - 285px)',
                  overflow: 'auto',
                  p: 4,
                  ml: -4,
                  mr: -4,
                }}
              >
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Name
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      fullWidth
                      value={values.Name ?? ''}
                      error={!!errors.Name}
                      helperText={errors.Name}
                      onChange={(e) =>
                        setFieldValue('Name', e.target.value, true)
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      Cpu And Memory
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <TextField
                      disabled
                      fullWidth
                      type='number'
                      value={values.CpuAndMemory ?? ''}
                      error={!!errors.CpuAndMemory}
                      helperText={errors.CpuAndMemory}
                      sx={{ '& .Mui-disabled': { background: grey[50] } }}
                      onChange={(e) =>
                        setFieldValue('CpuAndMemory', e.target.value)
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <Box
                      component='p'
                      color='text.primary'
                      fontSize={14}
                      mb={2}
                    >
                      App Package
                      <Box component='span' ml={1} sx={{ color: 'error.main' }}>
                        *
                      </Box>
                    </Box>
                    <AppPackageSelect
                      value={values.AppPackage ?? null}
                      error={!!errors.AppPackage}
                      helperText={errors.AppPackage}
                      onChange={(e, value) =>
                        setFieldValue(
                          'AppPackage',
                          value
                            ? {
                                ...value,
                                AppComponents: value?.AppComponents?.map(
                                  (app) => ({
                                    ...app,
                                    Environments:
                                      app?.RequiredEnviromentVariables
                                        ? app?.RequiredEnviromentVariables?.split(
                                            ',',
                                          ).map((e) => ({
                                            id: e,
                                            key: e,
                                            value: '',
                                          }))
                                        : [],
                                  }),
                                ),
                              }
                            : null,
                          true,
                        )
                      }
                    />
                  </Grid>

                  {values?.AppPackage?.AppComponents?.length && (
                    <Grid item xs={12} sm={12}>
                      <Box
                        component='p'
                        color='text.primary'
                        fontSize={14}
                        mt={3}
                        mb={2}
                      >
                        Container Apps
                      </Box>

                      <Box style={{ height: 375, width: '100%' }}>
                        <DataGrid
                          hideFooter
                          disableColumnSelector
                          disableColumnMenu
                          rows={values?.AppPackage?.AppComponents ?? []}
                          columns={columns}
                          getRowId={(row) => row.Id}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>

                {!!containerApp && (
                  <AddEnvironmentDrawer
                    visible={visible}
                    handleClose={handleClose}
                    title={containerApp.Name}
                    appType={containerApp.DeploymentType}
                    requiredEnviromentVariables={
                      containerApp.Environments?.map((e) => ({
                        ...e,
                        isValid: e.isValid ?? false,
                        isRequired: e.isRequired ?? true,
                      })) ?? []
                    }
                    setFieldValue={setFieldValue}
                    cron={containerApp?.Cron}
                    setContainerApps={({ environments, cron, isValid }) => {
                      const newAppPackage = {
                        ...values?.AppPackage,
                        Cron: cron,
                        AppComponents: values?.AppPackage?.AppComponents?.map(
                          (e) =>
                            e.Id === containerApp?.Id
                              ? {
                                  ...e,
                                  Environments: environments,
                                  Cron: cron,
                                  isValid,
                                }
                              : e,
                        ),
                      };

                      setFieldValue('AppPackage', newAppPackage);
                    }}
                  />
                )}
              </Box>

              <FooterWrapper
                className='footer'
                sx={{
                  margin: '0px -20px 0px -20px',
                  backgroundColor: '#f4f7fe',
                  height: '50px',
                }}
              >
                <Stack
                  direction='row'
                  justifyContent='end'
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    height: '100%',
                    p: 2,
                  }}
                >
                  <Button
                    variant='outlined'
                    onClick={onClickBackButton}
                    sx={{ mr: 3 }}
                  >
                    Cancel
                  </Button>

                  <LoadingButton
                    variant='contained'
                    loading={addItem.isPending}
                    sx={{ mr: 3 }}
                    onClick={submitForm}
                  >
                    Save
                  </LoadingButton>
                </Stack>
              </FooterWrapper>
            </Box>
          )}
        </Formik>
      </Box>
    </>
  );
};

export default Add;
