import * as Yup from 'yup';
import checkIsRequireTargetPort from '../helpers/checkIsRequireTargetPort';

export default Yup.object().shape({
  BranchName: Yup.string().required().label('Branch Name'),
  RepositoryUrl: Yup.string().required().label('Repository Url'),
  DockerfilePath: Yup.string().required().label('Dockerfile Path'),
  AccessToken: Yup.string().required().label('Access Token'),
  ImageName: Yup.string().required().label('Image Name'),
  RequiredEnviromentVariables: Yup.string()
    .required()
    .label('Required Environment Variables'),
  DeploymentType: Yup.object().required().label('Deployment Type'),
  TargetPort: Yup.string()
    .when('DeploymentType', {
      is: (val) => checkIsRequireTargetPort(val),
      then: Yup.string().required(),
      otherwise: Yup.string(),
    })
    .label('Target Port'),
});
