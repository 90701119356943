import jwtAxios from '@tenant/services/auth/jwt-auth';

export const CONTAINER_APP_APIS = {
  ROOT: 'api/app-instances/:appInstanceId/container-apps/:containerAppId',
};

const replaceEndpointUrl = (
  appInstanceId,
  containerAppId,
  endpoint = CONTAINER_APP_APIS.ROOT,
) => {
  return endpoint
    .replace(':appInstanceId', appInstanceId)
    .replace(':containerAppId', containerAppId);
};

export const getDetailContainerAppQuery = ({
  appInstanceId,
  containerAppId,
}) => {
  return jwtAxios.get(replaceEndpointUrl(appInstanceId, containerAppId));
};

export const startContainerApp = ({
  appInstanceId,
  containerAppId,
  ...payload
}) => {
  return jwtAxios.post(
    replaceEndpointUrl(appInstanceId, containerAppId) + ':start',
    payload,
  );
};

export const stopContainerApp = ({
  appInstanceId,
  containerAppId,
  ...payload
}) => {
  return jwtAxios.post(
    replaceEndpointUrl(appInstanceId, containerAppId) + ':stop',
    payload,
  );
};

export const getListLogContainerAppQuery = ({
  appInstanceId,
  containerAppId,
  ...payload
}) => {
  return jwtAxios.post(
    replaceEndpointUrl(appInstanceId, containerAppId) + '/logs',
    {
      appInstanceId,
      containerAppId,
      ...payload,
    },
  );
};

export const getListExecutionContainerAppQuery = ({
  appInstanceId,
  containerAppId,
  ...payload
}) => {
  return jwtAxios.get(
    replaceEndpointUrl(appInstanceId, containerAppId) + '/job-excutions',
    {
      appInstanceId,
      containerAppId,
      ...payload,
    },
  );
};

export const updateEnvironmentVariablesAppQuery = ({
  appInstanceId,
  containerAppId,
  ...payload
}) => {
  return jwtAxios.put(
    replaceEndpointUrl(appInstanceId, containerAppId) + '/enviroment-variables',
    payload,
  );
};

export const updateCronAppQuery = ({
  appInstanceId,
  containerAppId,
  ...payload
}) => {
  return jwtAxios.put(
    replaceEndpointUrl(appInstanceId, containerAppId) + '/Cron',
    payload.Cron,
  );
};
